import React, { useEffect, useState } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

const HeadHTML: React.FC = () => {
    const [manifestUrl, setManifestUrl] = useState('');

    const createManifest = () => {
        const manifest = {
            short_name: 'SALES NEST',
            name: `Create SALES NEST sample`,
            icons: [
                {
                    src: require(`assets/img/logo.webp`),
                    sizes: '64x64 32x32 24x24 16x16',
                    type: 'image/x-icon',
                },
                {
                    src: require(`assets/img/logo.webp`),
                    type: 'image/png',
                    sizes: '192x192',
                    purpose: 'maskable',
                },
                {
                    src: require(`assets/img/logo.webp`),
                    type: 'image/png',
                    sizes: '192x192',
                    purpose: 'any',
                },
                {
                    src: require(`assets/img/logo.webp`),
                    type: 'image/png',
                    sizes: '512x512',
                },
            ],
            start_url: window.location.origin,
            display: 'standalone',
            theme_color: '#000',
            background_color: '#000',
        };

        const manifestJSON = JSON.stringify(manifest);
        const blob = new Blob([manifestJSON], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        setManifestUrl(manifestURL);
    };

    useEffect(() => {
        createManifest();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!manifestUrl) return;

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker
                    .register('/serviceWorker.js', { scope: '/' })
                    .then(reg => {
                        console.log('registered serviceWorker');
                    })
                    .catch(err => {
                        console.log(err);
                    });
            });
        }
    }, [manifestUrl]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-5EKVQYRR8P`;
        script.async = true;
        document.head.appendChild(script);

        const configScript = document.createElement('script');
        configScript.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag("js", new Date());
                gtag("config", "G-5EKVQYRR8P");
            `;
        document.head.appendChild(configScript);
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <link rel="manifest" href={manifestUrl} />
            </Helmet>
        </HelmetProvider>
    );
};

export default HeadHTML;
