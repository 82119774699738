import { Box, Button, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { telegramSender } from 'utils/telegramSender';
import userDataValidation from 'utils/validation/userDataValidation';

const Step2 = ({ setStep, step2Data, setStep2Data }) => {
    const { string }: any = useOutletContext();
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userPassword, setUserPassword] = useState('');

    const formik = useFormik({
        initialValues: {
            userEmail: '',
            userName: '',
            userPhone: '',
            userPassword: '',
        },
        validationSchema: userDataValidation,
        onSubmit: values => {
            setStep(2);
            setStep2Data(values);
        },
    });

    useEffect(() => {
        telegramSender({
            action: `ШАГ 2`,
        });
        setUserName(step2Data?.userName);
        setUserEmail(step2Data?.userEmail);
        setUserPhone(step2Data?.userPhone);
        setUserPassword(step2Data?.userPassword); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        formik.setValues({
            userName,
            userEmail,
            userPhone,
            userPassword,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName, userEmail, userPhone, userPassword]);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                formik.handleSubmit();
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    maxWidth: '600px',
                    m: '0 auto',
                    gap: 0.25,
                }}
            >
                <Box mb={1} sx={{ width: '100%' }}>
                    <Typography variant="h2" sx={{ lineHeight: 1.75 }}>
                        {string?.owner_information}
                    </Typography>
                </Box>
                <Box mb={2} sx={{ width: '100%' }}>
                    <Typography variant="h4" sx={{ lineHeight: 1.75, color: 'orange' }}>
                        {string?.create_note}
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={userName}
                        onChange={e => {
                            setUserName(e.target.value);
                        }}
                        type={'text'}
                        label={string?.owner_or_manager_name + '*'}
                        size="small"
                        fullWidth
                        error={Boolean(formik.errors.userName && formik.touched.userName)}
                    />
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary">
                        e.g: "Tom Johnson" or "Manager Alberto Bini"
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={userEmail}
                        onChange={e => {
                            setUserEmail(e.target.value);
                        }}
                        type={'text'}
                        label={string?.owner_or_manager_email + '*'}
                        size="small"
                        fullWidth
                        error={Boolean(formik.errors.userEmail && formik.touched.userEmail)}
                    />
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary">
                        e.g: "alberto-bini.manager@gmail.com"
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={userPhone}
                        onChange={e => {
                            // Фильтрация ввода, оставляя только цифры, плюс, минус, открывающую и закрывающую скобки
                            setUserPhone(e.target.value.replace(/[^\d+()-]/g, ''));
                        }}
                        type={'tel'} // Использование типа 'tel' для ввода номера телефона
                        label={string?.owner_or_manager_phone_number}
                        size="small"
                        fullWidth
                        error={Boolean(formik.errors.userPhone && formik.touched.userPhone)}
                        InputProps={{ inputProps: { pattern: '[0-9()+-]*' } }} // Ограничение ввода только цифрами и символами + - ( )
                    />
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary">
                        e.g: "+70929001111"
                    </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={userPassword}
                        onChange={e => {
                            setUserPassword(e.target.value);
                        }}
                        type={'password'}
                        label={string?.owner_or_manager_admin_password + '*'}
                        size="small"
                        fullWidth
                        error={Boolean(formik.errors.userPassword && formik.touched.userPassword)}
                    />
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary"></Typography>
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary">
                        min 6 symbols
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: 1 }}>
                    <Button type="button" variant="outlined" onClick={() => setStep(0)}>
                        {string?.prev}
                    </Button>
                    <Button type="submit" variant="contained">
                        {string?.next}
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

export default Step2;
