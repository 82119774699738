import { ThemeProvider } from '@mui/material';
import mainTheme from 'theme/mainTheme';
import LandingRouting from 'router/LandingRouting';
import { Toaster } from 'react-hot-toast';
import { useDevice } from 'hooks/useDevice';
import HeadHTML from 'layouts/HeadHTML';

const App = () => {
    const { sx } = useDevice();

    return (
        <ThemeProvider theme={mainTheme}>
            <Toaster
                position="top-right"
                toastOptions={{ style: { width: '100vw', maxWidth: sx ? '100vw' : '' }, duration: 3000 }}
            />
            <HeadHTML />
            <LandingRouting />
        </ThemeProvider>
    );
};

export default App;
