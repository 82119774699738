export const FORM_CREATE = {
    en: {
        store_information: 'Store information',
        owner_information: 'Owner information',
        complete: 'Complete',
        name_of_future_catalog: 'Name of future catalog',
        email_for_your_catalog: 'Email for your catalog',
        store_currency: 'Store currency',
        country_of_store: 'Country of store',
        store_language: 'Store language',
        store_product_types: 'Store product types',
        store_site_address: 'Store site address',
        images_format: 'Images format',
        next: 'Next',
        prev: 'Prev',
        confirm: 'Confirm',
        create_note: 'Please note that all information provided can be edited in the future.',
        owner_or_manager_admin_password: 'Owner or Manager Admin Password',
        owner_or_manager_phone_number: 'Owner or Manager Phone Number',
        owner_or_manager_email: 'Owner or Manager Email',
        owner_or_manager_name: 'Owner or Manager Name',
        confirm_information:
            'Thank you for filling out the form! Verification may take several hours. To confirm the registration of the catalog, please enter your email where we will send you a link to your online catalog and admin panel.',
    },
    ua: {
        store_information: 'Інформація про магазин',
        owner_information: 'Інформація про власника',
        complete: 'Завершити',
        name_of_future_catalog: 'Назва майбутнього каталогу',
        email_for_your_catalog: 'Електронна пошта для вашого каталогу',
        store_currency: 'Валюта магазину',
        country_of_store: 'Країна магазину',
        store_language: 'Мова магазину',
        store_product_types: 'Типи товару магазину',
        store_site_address: 'Адреса сайту магазину',
        images_format: 'Формат зображень',
        next: 'Далі',
        prev: 'Назад',
        confirm: 'Підтвердити',
        create_note: 'Зверніть увагу, що всю надану інформацію можна буде редагувати в майбутньому.',
        owner_or_manager_admin_password: 'Пароль власника або менеджера адміністратора',
        owner_or_manager_phone_number: 'Номер телефону власника або менеджера',
        owner_or_manager_email: 'Електронна пошта власника або менеджера',
        owner_or_manager_name: 'Ім’я власника або менеджера',
        confirm_information:
            'Дякуємо за заповнення форми! Перевірка може зайняти кілька годин. Щоб підтвердити реєстрацію каталогу, будь ласка, введіть свою електронну пошту, на яку ми відправимо вам посилання на ваш онлайн-каталог і панель адміністратора.',
    },
    pl: {
        store_information: 'Informacje o sklepie',
        owner_information: 'Informacje o właścicielu',
        complete: 'Zakończ',
        name_of_future_catalog: 'Nazwa przyszłego katalogu',
        email_for_your_catalog: 'Adres e-mail do Twojego katalogu',
        store_currency: 'Waluta sklepu',
        country_of_store: 'Kraj sklepu',
        store_language: 'Język sklepu',
        store_product_types: 'Typy produktów sklepu',
        store_site_address: 'Adres strony sklepu',
        images_format: 'Format obrazów',
        next: 'Dalej',
        prev: 'Wstecz',
        confirm: 'Potwierdź',
        create_note: 'Prosimy zauważyć, że wszystkie podane informacje będą mogły być edytowane w przyszłości.',
        owner_or_manager_admin_password: 'Hasło administratora właściciela lub menedżera',
        owner_or_manager_phone_number: 'Numer telefonu właściciela lub menedżera',
        owner_or_manager_email: 'E-mail właściciela lub menedżera',
        owner_or_manager_name: 'Imię właściciela lub menedżera',
        confirm_information:
            'Dziękujemy za wypełnienie formularza! Weryfikacja może potrwać kilka godzin. Aby potwierdzić rejestrację katalogu, prosimy wprowadzić swój adres e-mail, na który wyślemy link do Państwa katalogu online i panelu administratora.',
    },

    cz: {
        store_information: 'Informace o obchodě',
        owner_information: 'Informace o majiteli',
        complete: 'Dokončit',
        name_of_future_catalog: 'Název budoucího katalogu',
        email_for_your_catalog: 'E-mail pro váš katalog',
        store_currency: 'Měna obchodu',
        country_of_store: 'Země obchodu',
        store_language: 'Jazyk obchodu',
        store_product_types: 'Typy produktů obchodu',
        store_site_address: 'Adresa webu obchodu',
        images_format: 'Formát obrázků',
        next: 'Další',
        prev: 'Předchozí',
        confirm: 'Potvrdit',

        create_note: 'Vezměte prosím na vědomí, že všechny poskytnuté informace lze v budoucnu upravit.',
        owner_or_manager_admin_password: 'Heslo správce majitele nebo manažera',
        owner_or_manager_phone_number: 'Telefonní číslo majitele nebo manažera',
        owner_or_manager_email: 'E-mail majitele nebo manažera',
        owner_or_manager_name: 'Jméno majitele nebo manažera',
        confirm_information:
            'Děkujeme za vyplnění formuláře! Kontrola může trvat několik hodin. Pro potvrzení registrace katalogu zadejte svůj e-mail, na který vám zašleme odkaz na váš online katalog a administrátorský panel.',
    },
    ru: {
        store_information: 'Информация о магазине',
        owner_information: 'Информация о владельце',
        complete: 'Завершить',
        name_of_future_catalog: 'Название будущего каталога',
        email_for_your_catalog: 'Электронная почта для вашего каталога',
        store_currency: 'Валюта магазина',
        country_of_store: 'Страна магазина',
        store_language: 'Язык магазина',
        store_product_types: 'Типы товаров магазина',
        store_site_address: 'Адрес сайта магазина',
        images_format: 'Формат изображений',
        next: 'Далее',
        prev: 'Назад',
        confirm: 'Подтвердить',
        create_note: 'Обратите внимание, что вся предоставленная информация может быть изменена в будущем.',
        owner_or_manager_admin_password: 'Пароль администратора владельца или менеджера',
        owner_or_manager_phone_number: 'Номер телефона владельца или менеджера',
        owner_or_manager_email: 'Электронная почта владельца или менеджера',
        owner_or_manager_name: 'Имя владельца или менеджера',
        confirm_information: `Благодарим за заполнение формы! Проверка может занять несколько часов. Чтобы подтвердить регистрацию каталога, введите вашу электронную почту куда мы вышлем вам ссылку на ваш онлайн каталог и админ панель.`,
    },

    et: {
        store_information: 'Poepinfo',
        owner_information: 'Omaniku info',
        complete: 'Lõpeta',
        name_of_future_catalog: 'Tulevase kataloogi nimi',
        email_for_your_catalog: 'E-posti aadress teie kataloogi jaoks',
        store_currency: 'Poe valuuta',
        country_of_store: 'Poe riik',
        store_language: 'Poe keel',
        store_product_types: 'Poe toodete tüübid',
        store_site_address: 'Poe veebiaadress',
        images_format: 'Piltide formaat',
        next: 'Järgmine',
        prev: 'Eelmine',
        confirm: 'Kinnita',
        create_note: 'Pange tähele, et kõiki esitatud andmeid saab tulevikus muuta.',
        owner_or_manager_admin_password: 'Omaniku või halduri administraatori parool',
        owner_or_manager_phone_number: 'Omaniku või halduri telefoninumber',
        owner_or_manager_email: 'Omaniku või halduri e-post',
        owner_or_manager_name: 'Omaniku või halduri nimi',
        confirm_information:
            'Täname vormi täitmise eest! Kontroll võib võtta mitu tundi. Kataloogi registreerimise kinnitamiseks sisestage palun oma e-posti aadress, kuhu saadame lingi teie veebikataloogi ja administraatoripaneeli.',
    },
    lv: {
        store_information: 'Veikala informācija',
        owner_information: 'Īpašnieka informācija',
        complete: 'Pabeigt',
        name_of_future_catalog: 'Nākotnes kataloga nosaukums',
        email_for_your_catalog: 'E-pasts jūsu katalogam',
        store_currency: 'Veikala valūta',
        country_of_store: 'Veikala valsts',
        store_language: 'Veikala valoda',
        store_product_types: 'Veikala produktu veidi',
        store_site_address: 'Veikala vietnes adrese',
        images_format: 'Attēlu formāts',
        next: 'Nākamais',
        prev: 'Iepriekšējais',
        confirm: 'Apstiprināt',
        create_note: 'Lūdzu, ņemiet vērā, ka visu sniegto informāciju nākotnē varēs rediģēt.',
        owner_or_manager_admin_password: 'Īpašnieka vai pārvaldnieka administratora parole',
        owner_or_manager_phone_number: 'Īpašnieka vai pārvaldnieka tālruņa numurs',
        owner_or_manager_email: 'Īpašnieka vai pārvaldnieka e-pasts',
        owner_or_manager_name: 'Īpašnieka vai pārvaldnieka vārds',
        confirm_information:
            'Paldies par formas aizpildīšanu! Verifikācija var aizņemt dažas stundas. Lai apstiprinātu kataloga reģistrāciju, lūdzu, ievadiet savu e-pasta adresi, uz kuru mēs nosūtīsim saiti uz jūsu tiešsaistes katalogu un administratora paneli.',
    },
    lt: {
        store_information: 'Parduotuvės informacija',
        owner_information: 'Savininko informacija',
        complete: 'Užbaigti',
        name_of_future_catalog: 'Būsimo katalogo pavadinimas',
        email_for_your_catalog: 'El. paštas jūsų katalogui',
        store_currency: 'Parduotuvės valiuta',
        country_of_store: 'Parduotuvės šalis',
        store_language: 'Parduotuvės kalba',
        store_product_types: 'Parduotuvės produktų tipai',
        store_site_address: 'Parduotuvės svetainės adresas',
        images_format: 'Vaizdų formatas',
        next: 'Kitas',
        prev: 'Atgal',
        confirm: 'Patvirtinti',
        create_note: 'Atkreipkite dėmesį, kad visa pateikta informacija ateityje gali būti redaguojama.',
        owner_or_manager_admin_password: 'Savininko arba vadybininko administratoriaus slaptažodis',
        owner_or_manager_phone_number: 'Savininko arba vadybininko telefono numeris',
        owner_or_manager_email: 'Savininko arba vadybininko el. paštas',
        owner_or_manager_name: 'Savininko arba vadybininko vardas',
        confirm_information:
            'Dėkojame už formos užpildymą! Patikrinimas gali užtrukti kelias valandas. Norėdami patvirtinti katalogo registraciją, prašome įvesti savo elektroninio pašto adresą, į kurią mes atsiųsime jums nuorodą į jūsų internetinį katalogą ir administratoriaus skydelį.',
    },
    de: {
        store_information: 'Geschäftsinformationen',
        owner_information: 'Informationen zum Besitzer',
        complete: 'Abschließen',
        name_of_future_catalog: 'Name des zukünftigen Katalogs',
        email_for_your_catalog: 'E-Mail für Ihren Katalog',
        store_currency: 'Währung des Geschäfts',
        country_of_store: 'Land des Geschäfts',
        store_language: 'Sprache des Geschäfts',
        store_product_types: 'Produkttypen des Geschäfts',
        store_site_address: 'Webadresse des Geschäfts',
        images_format: 'Bildformat',
        next: 'Weiter',
        prev: 'Zurück',
        confirm: 'Bestätigen',
        create_note: 'Bitte beachten Sie, dass alle angegebenen Informationen in Zukunft bearbeitet werden können.',
        owner_or_manager_admin_password: 'Administratorpasswort des Besitzers oder Managers',
        owner_or_manager_phone_number: 'Telefonnummer des Besitzers oder Managers',
        owner_or_manager_email: 'E-Mail des Besitzers oder Managers',
        owner_or_manager_name: 'Name des Besitzers oder Managers',
        confirm_information:
            'Vielen Dank für das Ausfüllen des Formulars! Die Überprüfung kann einige Stunden dauern. Um die Registrierung des Katalogs zu bestätigen, geben Sie bitte Ihre E-Mail-Adresse ein, an die wir Ihnen einen Link zu Ihrem Online-Katalog und zum Admin-Panel senden werden.',
    },
    fr: {
        store_information: 'Informations sur le magasin',
        owner_information: 'Informations sur le propriétaire',
        complete: 'Terminer',
        name_of_future_catalog: 'Nom du futur catalogue',
        email_for_your_catalog: 'E-mail pour votre catalogue',
        store_currency: 'Devise du magasin',
        country_of_store: 'Pays du magasin',
        store_language: 'Langue du magasin',
        store_product_types: 'Types de produits du magasin',
        store_site_address: 'Adresse du site du magasin',
        images_format: 'Format des images',
        next: 'Suivant',
        prev: 'Précédent',
        confirm: 'Confirmer',
        create_note: 'Veuillez noter que toutes les informations fournies pourront être modifiées ultérieurement.',
        owner_or_manager_admin_password: 'Mot de passe administrateur du propriétaire ou du gestionnaire',
        owner_or_manager_phone_number: 'Numéro de téléphone du propriétaire ou du gestionnaire',
        owner_or_manager_email: 'E-mail du propriétaire ou du gestionnaire',
        owner_or_manager_name: 'Nom du propriétaire ou du gestionnaire',
        confirm_information:
            "Merci d'avoir rempli le formulaire ! La vérification peut prendre plusieurs heures. Pour confirmer l'inscription du catalogue, veuillez saisir votre adresse e-mail à laquelle nous vous enverrons un lien vers votre catalogue en ligne et le panneau d'administration.",
    },
    es: {
        store_information: 'Información de la tienda',
        owner_information: 'Información del propietario',
        complete: 'Completo',
        name_of_future_catalog: 'Nombre del catálogo futuro',
        email_for_your_catalog: 'Correo electrónico para tu catálogo',
        store_currency: 'Moneda de la tienda',
        country_of_store: 'País de la tienda',
        store_language: 'Idioma de la tienda',
        store_product_types: 'Tipos de productos de la tienda',
        store_site_address: 'Dirección del sitio de la tienda',
        images_format: 'Formato de imágenes',
        next: 'Siguiente',
        prev: 'Anterior',
        confirm: 'Confirmar',
        create_note: 'Tenga en cuenta que toda la información proporcionada se puede editar en el futuro.',
        owner_or_manager_admin_password: 'Contraseña del propietario o administrador',
        owner_or_manager_phone_number: 'Número de teléfono del propietario o administrador',
        owner_or_manager_email: 'Correo electrónico del propietario o administrador',
        owner_or_manager_name: 'Nombre del propietario o administrador',
        confirm_information:
            '¡Gracias por completar el formulario! La verificación puede tomar varias horas. Para confirmar el registro del catálogo, ingrese su correo electrónico donde le enviaremos un enlace a su catálogo en línea y panel de administración.',
    },
};
