// ROOT

export const ROUTES = {
    PAGE_401: '/auth-error',
    PAGE_403: '/bad-request',
    PAGE_404: '/page-not-found',
    PAGE_500: '/server-error',
};

// ERROR PAGES

export const ERROR_PAGE = {
    page_401: () => `${ROUTES?.PAGE_401}`,
    page_403: () => `${ROUTES?.PAGE_403}`,
    page_404: () => `${ROUTES?.PAGE_404}`,
    page_500: () => `${ROUTES?.PAGE_500}`,
};
