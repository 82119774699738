import { Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Colors } from 'constants/colors';
import { useDevice } from 'hooks/useDevice';
import Slides from './Slides';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { LandingContextInterface } from 'types/outlet_context_models';

const Hero = () => {
    const { string }: LandingContextInterface = useOutletContext();
    const { sx, l } = useDevice();

    const navigate = useNavigate();

    return (
        <Grid container className="LandingHero" justifyContent="center" alignItems="center">
            <Grid px={l ? 4 : 0} pl={l ? 4 : 25} pb={l ? 2 : 0} pt={l ? 4 : 0} xs={sx ? 12 : 6} sx={{ height: '100%' }}>
                <Box>
                    <Typography sx={{ fontSize: l ? 24 : 42, fontWeight: 700, lineHeight: 1 }} color={Colors?.WHITE}>
                        {string?.hero_title}
                    </Typography>
                    <Typography
                        color={Colors?.WHITE}
                        sx={{ mt: 2, fontSize: l ? 18 : 24, fontWeight: sx ? 40 : 500, lineHeight: 1.1 }}
                    >
                        <b>Sales Nest</b> {string?.hero_description}
                    </Typography>
                </Box>
                <Box sx={{ width: '100%', display: 'flex' }}>
                    <Box mt={5} display="flex" sx={{ alignItems: 'center', width: l ? '100%' : '95%' }}>
                        <Button
                            color="info"
                            variant="contained"
                            sx={{
                                height: l ? 40 : 50,
                                borderRadius: '12px',
                                minWidth: l ? 120 : 180,
                                fontSize: l ? 12 : 16,
                                whiteSpace: 'nowrap',
                                fontWeight: 700,
                                m: '0 auto',
                            }}
                            onClick={() => {
                                navigate('/create-catalog');
                            }}
                        >
                            {string?.create_catalog}
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid xs={sx ? 12 : 6} p={sx ? 0 : 2}>
                <Slides />
            </Grid>
        </Grid>
    );
};

export default Hero;
