import { Box, Button, TextField } from '@mui/material';
import { Colors } from 'constants/colors';
import useSendRequest from 'hooks/useSendRequest';
import { DialogWindowType } from 'layouts/hooks/useFormsApp';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { LandingContextInterface } from 'types/outlet_context_models';

const RequestButton = () => {
    const { string, handleOpenDialog }: LandingContextInterface = useOutletContext();
    const [contacts, setContacts] = useState('');
    const { error, handleRequest, success, setSuccess, setError } = useSendRequest();

    useEffect(() => {
        if (success) {
            handleOpenDialog(DialogWindowType?.SUCCESS_REQUEST);
            setContacts('');
            setSuccess(false);
            setError(false);
        }
    }, [success]); // eslint-disable-line

    return (
        <Box display="flex" sx={{ alignItems: 'center', maxWidth: 800 }}>
            <TextField
                value={contacts}
                onChange={e => {
                    setContacts(e?.target?.value);
                }}
                sx={{
                    backgroundColor: Colors?.WHITE,
                    borderRadius: '4px 0 0 4px',
                    height: 30,
                    flexGrow: 1,
                    '& .MuiInputBase-root': {
                        height: 30,
                        paddingRight: 0,
                        borderRadius: '4px 0 0 4px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '4px 0 0 4px',
                    },
                    width: 240,
                }}
                size="small"
                inputProps={{ sx: { height: 30, padding: '0 10px', fontSize: 12 } }}
                placeholder={string?.enter_phone_or_email}
                fullWidth
                variant="outlined"
                error={error}
            />
            <Button
                color="info"
                variant="contained"
                onClick={() => {
                    handleRequest(contacts);
                }}
                sx={{
                    height: 30,
                    borderRadius: '0 4px 4px 0',
                    minWidth: 90,
                    fontSize: 12,
                    whiteSpace: 'nowrap',
                    fontWeight: 700,
                    boxShadow: 'none',
                }}
            >
                {string?.request}
            </Button>
        </Box>
    );
};

export default RequestButton;
