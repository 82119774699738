export const LANGS = {
    en: {
        english: 'English',
        polish: 'Polish',
        ukrainian: 'Ukrainian',
        russian: 'Russian',
        czech: 'Czech',
        french: 'French',
        spanish: 'Spanish',
        estonian: 'Estonian',
        latvian: 'Latvian',
        lithuanian: 'Lithuanian',
        german: 'Deutsch',
    },
    fr: {
        english: 'Anglais',
        polish: 'Polonais',
        ukrainian: 'Ukrainien',
        russian: 'Russe',
        czech: 'Tchèque',
        french: 'Français',
        spanish: 'Espagnol',
        bulgarian: 'Bulgare',
        estonian: 'Estonien',
        latvian: 'Letton',
        lithuanian: 'Lituanien',
        german: 'Deutsch',
    },
    es: {
        english: 'English',
        polish: 'Polaco',
        ukrainian: 'Ucraniano',
        russian: 'Ruso',
        czech: 'Checo',
        french: 'Francés',
        spanish: 'Español',
        estonian: 'Estonio',
        latvian: 'Letón',
        lithuanian: 'Lituano',
        german: 'Deutsch',
    },
    ua: {
        english: 'English',
        polish: 'Польська',
        ukrainian: 'Українська',
        russian: 'Російська',
        czech: 'Чеська',
        french: 'Французька',
        spanish: 'Іспанська',
        estonian: 'Естонська',
        hungarian: 'Угорська',
        latvian: 'Латвійська',
        lithuanian: 'Литовська',
        german: 'Нiмецька',
    },
    pl: {
        english: 'Angielski',
        polish: 'Polski',
        ukrainian: 'Ukraiński',
        russian: 'Rosyjski',
        czech: 'Czeski',
        french: 'Francuski',
        spanish: 'Hiszpański',
        estonian: 'Estoński',
        hungarian: 'Węgierski',
        latvian: 'Łotewski',
        lithuanian: 'Litewski',
        german: 'Deutsch',
    },
    cz: {
        english: 'Anglický',
        polish: 'Polský',
        ukrainian: 'Ukrajinský',
        russian: 'Ruský',
        czech: 'Český',
        french: 'Francouzský',
        spanish: 'Španělský',
        estonian: 'Estonský',
        hungarian: 'Maďarský',
        latvian: 'Lotyšský',
        lithuanian: 'Litevský',
        german: 'Deutsch',
    },
    ru: {
        english: 'Английский',
        polish: 'Польский',
        ukrainian: 'Украинский',
        russian: 'Русский',
        czech: 'Чешский',
        french: 'Французский',
        spanish: 'Испанский',
        estonian: 'Эстонский',
        latvian: 'Латышский',
        lithuanian: 'Литовский',
        german: 'Немецкий',
    },

    et: {
        english: 'English',
        polish: 'Polish',
        ukrainian: 'Ukrainian',
        russian: 'Russian',
        czech: 'Czech',
        french: 'French',
        spanish: 'Spanish',
        estonian: 'Estonian',
        latvian: 'Latvian',
        lithuanian: 'Lithuanian',
        german: 'Deutsch',
    },
    hu: {
        english: 'English',
        polish: 'Polish',
        ukrainian: 'Ukrainian',
        russian: 'Russian',
        czech: 'Czech',
        french: 'French',
        spanish: 'Spanish',
        estonian: 'Estonian',
        latvian: 'Latvian',
        lithuanian: 'Lithuanian',
        german: 'Deutsch',
    },
    lv: {
        english: 'English',
        polish: 'Polish',
        ukrainian: 'Ukrainian',
        russian: 'Russian',
        czech: 'Czech',
        french: 'French',
        spanish: 'Spanish',
        estonian: 'Estonian',
        latvian: 'Latvian',
        lithuanian: 'Lithuanian',
        german: 'Deutsch',
    },
    lt: {
        english: 'English',
        polish: 'Polish',
        ukrainian: 'Ukrainian',
        russian: 'Russian',
        czech: 'Czech',
        french: 'French',
        spanish: 'Spanish',
        estonian: 'Estonian',
        latvian: 'Latvian',
        lithuanian: 'Lithuanian',
        german: 'Deutsch',
    },
    de: {
        english: 'Englisch',
        polish: 'Polnisch',
        ukrainian: 'Ukrainisch',
        russian: 'Russisch',
        czech: 'Tschechisch',
        french: 'Französisch',
        spanish: 'Spanisch',
        bulgarian: 'Bulgarisch',
        estonian: 'Estnisch',
        latvian: 'Lettisch',
        lithuanian: 'Litauisch',
        german: 'Deutsch',
    },
};
