import { Box, Button, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { telegramSender } from 'utils/telegramSender';
import storeDataValidation from 'utils/validation/storeDataValidation';

const Step1 = ({ setStep, step1Data, setStep1Data }) => {
    const { string }: any = useOutletContext();
    const [storeName, setStoreName] = useState('');
    const [storeCurrency, setStoreCurrency] = useState('');
    const [storeLanguage, setStoreLanguage] = useState('');
    const [storeProductType, setStoreProductType] = useState('');
    const [storePhotoFormat, setStorePhotoFormat] = useState('');

    const formik = useFormik({
        initialValues: {
            storeName: '',
            storeCurrency: '',
            storeLanguage: '',
            storeProductType: '',
            storePhotoFormat: '',
        },
        validationSchema: storeDataValidation,
        onSubmit: values => {
            setStep(1);
            setStep1Data(values);
        },
    });

    useEffect(() => {
        telegramSender({
            action: `ШАГ 1`,
        });
        setStoreName(step1Data?.storeName);
        setStoreCurrency(step1Data?.storeCurrency);
        setStoreLanguage(step1Data?.storeLanguage);
        setStoreProductType(step1Data?.storeProductType);
        setStorePhotoFormat(step1Data?.storePhotoFormat); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        formik.setValues({
            storeName,
            storeCurrency,
            storeLanguage,
            storeProductType,
            storePhotoFormat,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeName, storeCurrency, storeLanguage, storeProductType, storePhotoFormat]);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                formik.handleSubmit();
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    maxWidth: '600px',
                    m: '0 auto',
                    gap: 0.25,
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <Typography variant="h2" sx={{ lineHeight: 1.75 }}>
                        {string?.store_information}
                    </Typography>
                </Box>
                <Box mb={2} sx={{ width: '100%' }}>
                    <Typography variant="h4" sx={{ lineHeight: 1.75, color: 'orange' }}>
                        {string?.create_note}
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={storeName}
                        onChange={e => {
                            setStoreName(e.target.value);
                        }}
                        type={'text'}
                        label={string?.name_of_future_catalog + '*'}
                        size="small"
                        fullWidth
                        error={Boolean(formik.errors.storeName && formik.touched.storeName)}
                    />
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary">
                        e.g: "Alberto Bini"
                    </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={storeCurrency}
                        onChange={e => {
                            setStoreCurrency(e.target.value);
                        }}
                        type={'text'}
                        label={string?.store_currency + '*'}
                        size="small"
                        fullWidth
                        error={Boolean(formik.errors.storeCurrency && formik.touched.storeCurrency)}
                    />
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary">
                        e.g: "USD" or "$"
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={storeLanguage}
                        onChange={e => {
                            setStoreLanguage(e.target.value);
                        }}
                        type={'text'}
                        label={string?.store_language + '*'}
                        size="small"
                        fullWidth
                        error={Boolean(formik.errors.storeLanguage && formik.touched.storeLanguage)}
                    />
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary">
                        e.g: "English" or "English, Poland..." or just "en, pl..."
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={storeProductType}
                        onChange={e => {
                            setStoreProductType(e.target.value);
                        }}
                        type={'text'}
                        label={string?.store_product_types + '*'}
                        size="small"
                        fullWidth
                        error={Boolean(formik.errors.storeProductType && formik.touched.storeProductType)}
                    />
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary">
                        e.g: "Shoes" or 'Outerwear'
                    </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={storePhotoFormat}
                        onChange={e => {
                            setStorePhotoFormat(e.target.value);
                        }}
                        type={'text'}
                        label={string?.images_format}
                        size="small"
                        fullWidth
                    />
                    <Typography sx={{ my: 1, fontSize: 12 }} color="secondary">
                        e.g: "3 x 4" or "6 x 9"
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Button type="submit" variant="contained">
                        {string?.next}
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

export default Step1;
