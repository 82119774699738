import Grid from '@mui/material/Unstable_Grid2';
import Hero from './Hero';
import { useOutletContext } from 'react-router-dom';
import { LandingContextInterface } from 'types/outlet_context_models';
import CallBackButton from 'components/atoms/Buttons/CallBackButton';
import MessageButton from 'components/atoms/Buttons/MessageButton';
import { DialogWindowType } from 'layouts/hooks/useFormsApp';
import { Colors } from 'constants/colors';
import Advertizing from './Advertizing';
import CreateSteps from './CreateSteps';
import SupportSteps from './SupportSteps';
import UsageCases from './UsageCases';
import { Box } from '@mui/material';
import AdditionalBenefits from './AdditionalBenefits';

const LandingHomePage = () => {
    const { handleOpenDialog, handleSetDialogState }: LandingContextInterface = useOutletContext();

    return (
        <Box>
            <CallBackButton path={'/contacts'} />
            <MessageButton
                action={() => {
                    handleSetDialogState({ note: 'Sales Nest' });
                    handleOpenDialog(DialogWindowType?.QUESTION);
                }}
            />
            <Grid xs={12} container sx={{ width: '100%', overflow: 'hidden' }}>
                <Grid xs={12} sx={{ background: Colors?.LIGHT_BLUE }}>
                    <Hero />
                </Grid>
                <Grid xs={12} sx={{ background: Colors?.WHITE }}>
                    <CreateSteps />
                </Grid>
                <Grid xs={12} sx={{ background: Colors?.GRAY_100 }}>
                    <UsageCases />
                </Grid>
                <Grid xs={12} sx={{ background: Colors?.WHITE }}>
                    <Advertizing />
                </Grid>
                <Grid xs={12} sx={{ background: Colors?.GRAY_100 }}>
                    <AdditionalBenefits />
                </Grid>
                <Grid xs={12} sx={{ background: Colors?.WHITE }}>
                    <SupportSteps />
                </Grid>
            </Grid>
        </Box>
    );
};

export default LandingHomePage;
