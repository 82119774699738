import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';
import { ROUTES } from './routes';
import { LangResInterface, useGetLanguage } from 'hooks/useGetLanguage';
import PAGE_404 from 'pages/TechPages/404';
import PAGE_401 from 'pages/TechPages/401';
import PAGE_403 from 'pages/TechPages/403';
import PAGE_500 from 'pages/TechPages/500';
import LandingLayout from 'layouts/Landing/LandingLayout';
import LandingHomePage from 'pages/LandingWebPage/LandingHomePage';
import LandingContacts from 'pages/LandingWebPage/LandingContacts';
import axios from 'axios';
import { getStorageItem, setStorageItem } from 'utils/storageUtils';
import { useIsMount } from '../hooks/useIsMount';
import CreateCatalog from 'pages/LandingWebPage/CreateCatalog';

const LandingRouting = () => {
    const mount = useIsMount();
    const [lang, setLanguage] = useState<string | null>(null);
    const { currentLanguage }: LangResInterface = useGetLanguage({ lang: lang || 'en' });

    useEffect(() => {
        if (mount) return;
        const updateLang = async () => {
            try {
                await setStorageItem('sn_lang_val', JSON.stringify(lang));
            } catch (error) {
                console.error('Error setting storage item:', error);
            }
        };

        updateLang();
    }, [lang, mount]); // eslint-disable-line

    useEffect(() => {
        const fetchLang = async () => {
            try {
                const storedItems = await getStorageItem('sn_lang_val');
                if (storedItems) {
                    setLanguage(JSON.parse(storedItems));
                } else {
                    axios
                        .get('https://ipapi.co/json/')
                        .then(response => {
                            const userCountry = response.data.country_name;

                            if (userCountry === 'Ukraine') return setLanguage('ua');
                            if (userCountry === 'Poland') return setLanguage('pl');
                            if (userCountry === 'Czech') return setLanguage('cz');
                            if (userCountry === 'Spain') return setLanguage('es');
                            if (userCountry === 'France') return setLanguage('fr');
                            if (userCountry === 'Estonia') return setLanguage('et');
                            if (userCountry === 'Latvia') return setLanguage('lv');
                            if (userCountry === 'Lithuania') return setLanguage('lt');
                            if (userCountry === 'Germany') return setLanguage('de');

                            setLanguage('en');
                        })
                        .catch(error => {
                            console.error(error);
                        });
                }
            } catch (error) {
                console.error('Error getting storage item:', error);
            }
        };

        fetchLang();
    }, []);

    if (!lang) return null;

    const router = createBrowserRouter([
        {
            path: '/',
            errorElement: <PAGE_404 />,
            loader: () => <div>Loading...</div>,
            element: <LandingLayout lang={lang} setLang={setLanguage} currentLanguage={currentLanguage} />,
            children: [
                {
                    path: '/',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <LandingHomePage />
                        </Suspense>
                    ),
                },
                {
                    path: '/contacts',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <LandingContacts />
                        </Suspense>
                    ),
                },
                {
                    path: '/create-catalog',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <CreateCatalog />
                        </Suspense>
                    ),
                },
            ],
        },

        {
            path: ROUTES?.PAGE_401,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <PAGE_401 />
                </Suspense>
            ),
        },
        {
            path: ROUTES?.PAGE_403,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <PAGE_403 />
                </Suspense>
            ),
        },
        {
            path: ROUTES?.PAGE_404,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <PAGE_404 />
                </Suspense>
            ),
        },
        {
            path: ROUTES?.PAGE_500,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <PAGE_500 />
                </Suspense>
            ),
        },
        {
            path: '*',
            errorElement: <PAGE_404 />,
            loader: () => <div>Loading...</div>,
            element: <Navigate to={'/'} replace />,
        },
    ]);

    return <RouterProvider router={router} />;
};

export default LandingRouting;
