import { Box, Button, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { DialogWindowType } from 'layouts/hooks/useFormsApp';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { telegramSender } from 'utils/telegramSender';
import EmailValidation from 'utils/validation/EmailValidation';

const Step3 = ({ setStep, step1Data, step2Data }) => {
    const { string, handleOpenDialog }: any = useOutletContext();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: EmailValidation,
        onSubmit: values => {
            handleOpenDialog(DialogWindowType?.SUCCESS_REQUEST);
            telegramSender({
                action: `СОЗДАЛ КАТАЛОГ`,
                contacts: values?.email,
                text: `${JSON.stringify(step1Data)}, ${JSON.stringify(step2Data)}`,
            });
            navigate('/');
        },
    });

    useEffect(() => {
        telegramSender({
            action: `ШАГ 3`,
        });

        // Создание элемента скрипта для события конверсии
        const conversionScript = document.createElement('script');
        conversionScript.innerHTML = `
            gtag('event', 'conversion', {
                send_to: 'AW-16532045891/BjdfCLir_qYZEMOAjMs9',
                value: 1.0,
                currency: 'UAH',
                transaction_id: ''
            });
        `;

        // Создание элемента скрипта для отслеживания события конверсии при клике на ссылку или кнопку
        const conversionReportScript = document.createElement('script');
        conversionReportScript.innerHTML = `
            function gtag_report_conversion(url) {
                var callback = function () {
                    if (typeof url != 'undefined') {
                        window.location = url;
                    }
                };
                gtag('event', 'conversion', {
                    send_to: 'AW-16532045891/BjdfCLir_qYZEMOAjMs9',
                    value: 1.0,
                    currency: 'UAH',
                    transaction_id: '',
                    event_callback: callback
                });
                return false;
            }
        `;

        // Добавление скриптов в head документа
        document.head.appendChild(conversionScript);
        document.head.appendChild(conversionReportScript);

        // Функция очистки (удаления) скриптов при размонтировании компонента
        return () => {
            document.head.removeChild(conversionScript);
            document.head.removeChild(conversionReportScript);
        };
    }, []);

    useEffect(() => {
        formik.setValues({
            email,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                formik.handleSubmit();
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    maxWidth: '600px',
                    m: '0 auto',
                    gap: 0.25,
                }}
            >
                <Box mb={1} sx={{ width: '100%' }}>
                    <Typography variant="h2" sx={{ lineHeight: 1.75 }}>
                        {string?.complete}
                    </Typography>
                </Box>
                <Box mb={2} sx={{ width: '100%' }}>
                    <Typography variant="h4" sx={{ lineHeight: 1.75 }}>
                        {string?.confirm_information}
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <TextField
                        value={email}
                        onChange={e => {
                            setEmail(e.target.value);
                        }}
                        type={'text'}
                        label={string?.email}
                        size="small"
                        fullWidth
                        error={Boolean(formik.errors.email && formik.touched.email)}
                    />
                </Box>

                <Box mt={1} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: 1 }}>
                    <Button type="button" variant="outlined" onClick={() => setStep(1)}>
                        {string?.prev}
                    </Button>
                    <Button type="submit" variant="contained">
                        {string?.confirm}
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

export default Step3;
