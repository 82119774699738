import * as yup from 'yup';

const storeDataValidation = yup.object().shape({
    storeName: yup.string().required('required_field'),
    storeCurrency: yup.string().required('required_field'),
    storeLanguage: yup.string().required('required_field'),
    storeProductType: yup.string().required('required_field'),
});

export default storeDataValidation;
