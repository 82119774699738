export const LONG_PHRASES = {
    en: {
        call_back_messageA:
            'Hello! If you have questions, comments or need assistance, please contact us. We are ready to help you with your choice, provide you with additional information about the product or help you place your order.',
        call_back_messageB: 'You can contact us in any messenger available or by phone.',
        call_back_messageC: 'Thank you for your interest in our online catalogue!',
    },

    ua: {
        call_back_messageA:
            'Привіт! Якщо у вас є запитання, коментарі або потрібна допомога, зв’яжіться з нами. Ми готові допомогти вам з вибором, надати додаткову інформацію про товар або допомогти оформити замовлення.',
        call_back_messageB: 'Ви можете зв’язатися з нами в будь-якому доступному месенджері або по телефону.',
        call_back_messageC: 'Дякуємо за інтерес до нашого онлайн-каталогу!',
    },
    pl: {
        call_back_messageA:
            'Cześć! Jeśli masz pytania, uwagi lub potrzebujesz pomocy, skontaktuj się z nami. Chętnie pomożemy w dokonaniu wyboru, udzielimy dodatkowych informacji o produkcie lub pomożemy w złożeniu zamówienia.',
        call_back_messageB: 'Można się z nami skontaktować w dowolnym komunikatorze dostępnym lub telefonicznie.',
        call_back_messageC: 'Dziękujemy za zainteresowanie naszym katalogiem online!',
    },
    cz: {
        call_back_messageA:
            'Ahoj! Pokud máte dotazy, připomínky nebo potřebujete pomoc, kontaktujte nás. Jsme připraveni vám pomoci s výběrem, poskytnout doplňující informace o produktu nebo pomoci s objednávkou.',
        call_back_messageB: 'Můžete nás kontaktovat v jakémkoli uvedeném messengeru nebo telefonicky.',
        call_back_messageC: 'Děkujeme za váš zájem o náš internetový katalog!',
    },
    ru: {
        call_back_messageA:
            'Привет! Если у вас есть вопросы, комментарии или нужна помощь, пожалуйста, обратитесь к нам. Мы готовы помочь вам с выбором, предоставить вам дополнительную информацию о продукте или помочь с оформлением заказа.',
        call_back_messageB: 'Вы можете связаться с нами в любом доступном мессенджере или по телефону.',
        call_back_messageC: 'Спасибо за ваш интерес к нашему интернет-каталогу!',
    },

    et: {
        call_back_messageA:
            'Tere! Kui teil on küsimusi, kommentaare või vajate abi, võtke meiega ühendust. Oleme valmis teid valikuga aitama, pakkuma teile lisateavet toote kohta või aitama teil oma tellimust esitada.',
        call_back_messageB: 'Võite meiega ühendust võtta mis tahes saadaoleva sõnumitooja või telefoni teel.',
        call_back_messageC: 'Täname teid meie veebikataloogi vastu üles näidatud huvi eest!',
    },

    lv: {
        call_back_messageA:
            'Sveiki! Ja jums ir jautājumi, komentāri vai nepieciešama palīdzība, lūdzu, sazinieties ar mums. Mēs esam gatavi palīdzēt jums ar izvēli, sniegt papildu informāciju par produktu vai palīdzēt veikt pasūtījumu.',
        call_back_messageB: 'Jūs varat sazināties ar mums ar jebkuru pieejamu ziņu nosūtītāju vai pa tālruni.',
        call_back_messageC: 'Paldies par interesi par mūsu tiešsaistes katalogu!',
    },
    lt: {
        call_back_messageA:
            'Sveiki! Jei turite klausimų, pastabų ar reikia pagalbos, susisiekite su mumis. Mes esame pasiruošę jums padėti pasirinkti, suteikti papildomos informacijos apie produktą ar padėti jums padaryti užsakymą.',
        call_back_messageB: 'Galite susisiekti su mumis bet kuria prieinama žinutės siuntimo priemone ar telefonu.',
        call_back_messageC: 'Dėkojame už susidomėjimą mūsų internetiniu katalogu!',
    },
    de: {
        call_back_messageA:
            'Hallo! Wenn Sie Fragen, Kommentare oder Hilfe benötigen, kontaktieren Sie uns bitte. Wir helfen Ihnen gerne bei Ihrer Auswahl, geben Ihnen zusätzliche Informationen zu unserem Produkt oder unterstützen Sie bei Ihrer Bestellung.',
        call_back_messageB: 'Sie können uns über jeden verfügbaren Messenger oder telefonisch kontaktieren.',
        call_back_messageC: 'Vielen Dank für Ihr Interesse an unserem Online-Katalog!',
    },
};
