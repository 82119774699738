import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useOutletContext } from 'react-router-dom';

export default function HorizontalLinearAlternativeLabelStepper({ active }) {
    const { string }: any = useOutletContext();
    const steps = [string?.store_information, string?.owner_information, string?.complete];

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={active} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
