import { useEffect, useRef } from 'react';

const useStickyHeader = (fixedHeaderHeight = 55, earlyActivationOffset = 55) => {
    const headerRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const placeholderRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleScroll = () => {
            const header = headerRef.current;
            const container = containerRef.current;
            const placeholder = placeholderRef.current;

            if (!header || !container || !placeholder) return;

            const { top: containerTop, bottom: containerBottom } = container.getBoundingClientRect();

            if (containerTop <= fixedHeaderHeight - earlyActivationOffset && containerBottom >= header.offsetHeight) {
                placeholder.style.display = 'block';
                placeholder.style.height = `${header.offsetHeight}px`;
                header.style.position = 'fixed';
                header.style.top = `${fixedHeaderHeight}px`;
                header.style.width = `${container.offsetWidth}px`;
            } else if (containerBottom < header.offsetHeight + fixedHeaderHeight) {
                placeholder.style.display = 'none';
                header.style.position = 'absolute';
                header.style.top = `${containerBottom - header.offsetHeight}px`;
            } else {
                placeholder.style.display = 'none';
                header.style.position = 'relative';
                header.style.top = '0';
                header.style.width = '100%';
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [fixedHeaderHeight, earlyActivationOffset]);

    return { headerRef, containerRef, placeholderRef };
};

export default useStickyHeader;
