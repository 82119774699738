import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { useDevice } from 'hooks/useDevice';
import { useOutletContext } from 'react-router-dom';
import { LandingContextInterface } from 'types/outlet_context_models';
import { Colors } from 'constants/colors';
import useStickyHeader from 'hooks/useStickyHeader';
import RequestButton from './RequestButton';

const AdditionalBenefits = () => {
    const { string }: LandingContextInterface = useOutletContext(); //eslint-disable-line
    const { sx, s } = useDevice();
    const { headerRef, containerRef, placeholderRef } = useStickyHeader(55, 0); // Use the custom hook

    const data = [
        {
            title: string?.any_language,
            src: require('./additional_benefits_img/(2)_11zon.jpg'),
        },
        {
            title: string?.any_currency,
            src: require('./additional_benefits_img/(3)_11zon.jpg'),
        },
        {
            title: string?.no_limitations,
            src: require('./additional_benefits_img/(4)_11zon.jpg'),
        },
        {
            title: string?.unique_url,
            src: require('./additional_benefits_img/(5)_11zon.jpg'),
        },
    ];

    return (
        <Stack sx={{ width: '100%', alignItems: 'center', mb: 4 }}>
            <Box ref={containerRef} sx={{ width: '100%' }}>
                <div ref={placeholderRef} style={{ display: 'none' }}></div>
                <Box
                    ref={headerRef} // Reference the header
                    className="StickyHeader"
                    px={2}
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        background: Colors?.GRAY_300,
                        py: 1,
                        zIndex: 1,
                        opacity: 0.9,
                    }}
                >
                    <Typography sx={{ fontSize: sx ? 24 : 32, fontWeight: 700 }}>Additional Benefits</Typography>
                </Box>
                <Grid
                    className="Content"
                    px={sx ? 2 : 4}
                    container
                    spacing={2}
                    sx={{ maxWidth: 1200, margin: sx ? '12px auto' : '32px auto' }}
                >
                    {data.map(el => (
                        <Grid
                            xs={s ? 6 : 3}
                            key={el?.title}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Card
                                sx={{
                                    maxWidth: 300,
                                    borderTopLeftRadius: 12,
                                    borderTopRightRadius: 12,
                                    position: 'relative',
                                }}
                            >
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        backgroundColor: '#de9ceb',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: 0,
                                        width: '100%',
                                        height: 40,
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 12,
                                        opacity: 0.95,
                                        fontSize: 13,
                                        fontWeight: 500,
                                        color: Colors?.BLACK,
                                    }}
                                    component="div"
                                >
                                    {el?.title}
                                </Typography>
                                <CardMedia component="img" height="fit-content" image={el?.src} alt="image" />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box
                px={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    margin: '0 auto',
                }}
            >
                <RequestButton />
            </Box>
        </Stack>
    );
};

export default AdditionalBenefits;
