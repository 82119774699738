import { useState } from 'react';
import { telegramSender } from 'utils/telegramSender';

const useSendRequest = () => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleRequest = contacts => {
        if (!contacts || contacts?.length <= 6) {
            telegramSender({
                action: `ПРОБУЕТ ЗАКАЗ КАТАЛОГА`,
                contacts: `contacts: ${contacts}`,
            });
            setError(true);
        } else {
            telegramSender({
                action: `ЗАКАЗ КАТАЛОГА`,
                contacts: `contacts: ${contacts}`,
            });
            setError(false);
            setSuccess(true);
        }
    };

    return { handleRequest, error, success, setSuccess, setError };
};

export default useSendRequest;
