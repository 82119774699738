import QuestionForm from 'pages/DialogApp/QuestionForm';
import { DialogWindowType } from './hooks/useFormsApp';
import SuccessRequest from 'pages/DialogApp/SuccessRequest';
import SuccessMailSent from 'pages/DialogApp/SuccessMailSent';
import SomethingWentWrong from 'pages/DialogApp/SomethingWentWrong';
import { DialogStateInterface } from 'types/app_models';

interface Props {
    location;
    string;
    activeDialogWindow;
    handleOpenDialog;
    setAuth?;
    favorites?;
    cart?;
    dialogState?: DialogStateInterface | null;
    lang: string;
    setApiToken?: (token: string | null) => void;
}

const DialogApp = ({ string, activeDialogWindow, handleOpenDialog, dialogState }: Props) => {
    if (!activeDialogWindow) return null;

    return (
        <>
            <QuestionForm
                isOpen={activeDialogWindow === DialogWindowType?.QUESTION}
                setIsOpen={handleOpenDialog}
                string={string}
                dialogState={dialogState}
            />

            <SuccessRequest
                isOpen={activeDialogWindow === DialogWindowType?.SUCCESS_REQUEST}
                setIsOpen={handleOpenDialog}
                string={string}
            />

            <SuccessMailSent
                isOpen={activeDialogWindow === DialogWindowType?.SUCCESS_MAIL_SENT}
                setIsOpen={handleOpenDialog}
                string={string}
            />

            <SomethingWentWrong
                isOpen={activeDialogWindow === DialogWindowType?.WENT_WRONG}
                setIsOpen={handleOpenDialog}
                string={string}
            />
        </>
    );
};

export default DialogApp;
