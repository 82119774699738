import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useDevice } from 'hooks/useDevice';
import { useOutletContext } from 'react-router-dom';
import Slider from 'react-slick';
import { LandingContextInterface } from 'types/outlet_context_models';
import { telegramSender } from 'utils/telegramSender';

const Slides = () => {
    const { sm, sx, s, slx } = useDevice();
    const { string }: LandingContextInterface = useOutletContext(); //eslint-disable-line

    const SLIDES = [
        { desc: '910_11zon.jpg', mob: '710.jpg' },
        { desc: '908_11zon.jpg', mob: '708.jpg' },
        { desc: '911_11zon.jpg', mob: '711.jpg' },
        { desc: '902_11zon.jpg', mob: '702.jpg' },
        { desc: '904_11zon.jpg', mob: '704.jpg' },
        { desc: '906_11zon.jpg', mob: '706.jpg' },
        { desc: '912_11zon.jpg', mob: '712.jpg' },
        { desc: '913_11zon.jpg', mob: '713.jpg' },
        { desc: '914_11zon.jpg', mob: '714.jpg' },
        { desc: '915_11zon.jpg', mob: '715.jpg' },
        { desc: '601_11zon.jpg', mob: '801.jpg' },
        { desc: '602_11zon.jpg', mob: '802.jpg' },
        { desc: '603_11zon.jpg', mob: '803.jpg' },
        { desc: '605_11zon.jpg', mob: '805.jpg' },
        { desc: '606_11zon.jpg', mob: '806.jpg' },
    ];

    const settings = {
        fade: sx ? false : true,
        autoplay: true,
        autoplaySpeed: sx ? 3500 : 3000,
        arrows: false,
    };

    return (
        <Box>
            <Slider style={{ width: sx ? '100%' : '110%' }} touchThreshold={20} {...settings}>
                {SLIDES.map(el => (
                    <Box
                        key={el?.mob}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Grid
                            p={s ? 8 : slx ? 18 : sx ? 24 : 0.5}
                            py={sx ? 1 : 0.5}
                            xs={12}
                            container
                            sx={{
                                display: 'flex',
                                gap: 1,
                                flexWrap: 'nowrap',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Grid
                                xs={sx ? 12 : 2}
                                sx={{
                                    boxShadow: '0 0 5px 2px #d3d3d3',
                                    border: sx ? '10px solid #000' : '0.5vw solid #000',
                                    borderRadius: sm ? '32px' : '16px',
                                    background: `#000`,
                                    overflow: 'hidden',
                                    height: 'fit-content',
                                    zIndex: 1,
                                }}
                                mr={-2}
                                mb={1}
                            >
                                <img style={{ width: '100%' }} src={require(`./slides_images/${el.mob}`)} alt="" />
                            </Grid>
                            {!sx && (
                                <Grid
                                    xs={sx ? 0 : 10}
                                    sx={{
                                        border: '1vw solid #000',
                                        borderRadius: sm ? '12px' : '24px',
                                        background: `#fff`,
                                        overflow: 'hidden',
                                        height: 'fit-content',
                                        boxShadow: '0 0 5px 2px #d3d3d3',
                                    }}
                                >
                                    <Box sx={{ width: '100%' }}>
                                        <img
                                            style={{ width: '100%' }}
                                            src={require(`./slides_images/${el.desc}`)}
                                            alt=""
                                        />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                ))}
            </Slider>
            <Box my={1} mb={sx ? 3 : 1} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    size="large"
                    color="warning"
                    variant="contained"
                    onClick={() => {
                        window?.open('https://albertobini-brandeurope.com');
                        telegramSender({
                            action: `ЗАШЕЛ ПРИМЕР КАТАЛОГА`,
                        });
                    }}
                >
                    {string?.view_sample}
                </Button>
            </Box>
        </Box>
    );
};

export default Slides;
