import { useState } from 'react';
import Step1 from './CREATE_STEPPER/Step1';
import Step2 from './CREATE_STEPPER/Step2';
import Step3 from './CREATE_STEPPER/Step3';
import Stepper from './CREATE_STEPPER/Stepper';
import { Box } from '@mui/material';
import InstrumentalSubHeader from 'components/organisms/InstrumentalSubHeader/InstrumentalSubHeader';
import BackButton from 'components/atoms/Buttons/BackButton';
import { useDevice } from 'hooks/useDevice';

const CreateCatalog = () => {
    const { sx } = useDevice();
    const [active, setActive] = useState(0);
    const [step1Data, setStep1Data] = useState({});
    const [step2Data, setStep2Data] = useState({});

    return (
        <Box p={sx ? 2 : 4} my={4}>
            <InstrumentalSubHeader StartSlot={() => <BackButton />} />
            <Stepper active={active} />
            <Box my={2} py={2} px={2}>
                {active === 0 && <Step1 setStep={setActive} step1Data={step1Data} setStep1Data={setStep1Data} />}
                {active === 1 && <Step2 setStep={setActive} step2Data={step2Data} setStep2Data={setStep2Data} />}
                {active === 2 && <Step3 setStep={setActive} step1Data={step1Data} step2Data={step2Data} />}
            </Box>
        </Box>
    );
};

export default CreateCatalog;
