import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { useDevice } from 'hooks/useDevice';
import { useOutletContext } from 'react-router-dom';
import { LandingContextInterface } from 'types/outlet_context_models';
import { Color, Colors } from 'constants/colors';
import useStickyHeader from 'hooks/useStickyHeader';
import RequestButton from './RequestButton';

const UsageCases = () => {
    const { string }: LandingContextInterface = useOutletContext(); //eslint-disable-line
    const { sx, s, mx, l } = useDevice();
    const { headerRef, containerRef, placeholderRef } = useStickyHeader(55, 0); // Use the custom hook

    const data = [
        {
            title: string?.social_media_commerce,
            description: string?.social_media_commerce_description,
            src: require('./usage_imgs/01_11zon.jpg'),
        },
        {
            title: string?.retail_and_small_business,
            description: string?.retail_and_small_business_description,
            src: require('./usage_imgs/02_11zon.jpg'),
        },
        {
            title: string?.wholesale_and_medium_business,
            description: string?.wholesale_and_medium_business_description,
            src: require('./usage_imgs/03_11zon.jpg'),
        },
        {
            title: string?.b2b,
            description: string?.b2b_description,
            src: require('./usage_imgs/04_11zon.jpg'),
        },
    ];

    return (
        <Stack sx={{ width: '100%', alignItems: 'center', mb: 4 }}>
            <Box ref={containerRef} sx={{ width: '100%' }}>
                <div ref={placeholderRef} style={{ display: 'none' }}></div>
                <Box
                    ref={headerRef} // Reference the header
                    className="StickyHeader"
                    px={2}
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        background: Colors?.GRAY_300,
                        py: 1,
                        zIndex: 1,
                        opacity: 0.9,
                    }}
                >
                    <Typography sx={{ fontSize: sx ? 24 : 32, fontWeight: 700 }}>
                        {string?.scope_of_application}
                    </Typography>
                </Box>
                <Grid
                    className="Content"
                    px={sx ? 2 : 4}
                    container
                    spacing={2}
                    sx={{ maxWidth: 1600, margin: sx ? '12px auto' : '32px auto' }}
                >
                    {data.map(el => (
                        <Grid
                            xs={s ? 12 : mx ? 6 : 3}
                            key={el?.title}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Card
                                sx={{
                                    maxWidth: 400,
                                    borderTopLeftRadius: 12,
                                    borderTopRightRadius: 12,
                                    position: 'relative',
                                }}
                            >
                                <CardContent
                                    sx={{
                                        height: 60,
                                        textAlign: 'center',
                                        backgroundColor: Color?.INFO,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: 0,
                                        width: '100%',
                                        opacity: 0.9,
                                    }}
                                >
                                    <Typography
                                        sx={{ fontSize: 18, fontWeight: 500, color: Colors?.BLACK }}
                                        component="div"
                                    >
                                        {el?.title}
                                    </Typography>
                                </CardContent>
                                <CardMedia component="img" height="fit-content" image={el?.src} alt="image" />
                                <CardContent
                                    sx={{
                                        height: s ? 100 : sx ? 130 : mx ? 120 : l ? 140 : 100,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: Colors?.GRAY_300,
                                    }}
                                >
                                    <Typography
                                        variant={s ? 'h5' : 'h6'}
                                        color="text.secondary"
                                        sx={{ fontSize: 14, fontWeight: 500, color: Color?.SECONDARY_DARK }}
                                    >
                                        {el?.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box
                px={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    margin: '0 auto',
                }}
            >
                <RequestButton />
            </Box>
        </Stack>
    );
};

export default UsageCases;
