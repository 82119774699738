import { Box, Typography } from '@mui/material';
import { useDevice } from 'hooks/useDevice';

const LandingLogo = () => {
    const { sx } = useDevice();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }} onClick={() => {}}>
            <Box sx={{ boxShadow: '0 0 5px 1px #414141c5', borderRadius: '50%', border: '1px solid #383838' }}>
                <Box
                    sx={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: sx ? 40 : 45,
                        height: sx ? 40 : 45,
                        position: 'relative',
                    }}
                >
                    <img
                        src={require('assets/img/logo_cut.png')}
                        alt="Logo"
                        style={{
                            width: '112%',
                            height: '112%',
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    mt: 0.5,
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: sx ? 28 : 36,
                        fontWeight: 600,
                        color: '#161616',
                        textShadow: '#0000006a 0 0 2px',
                        mr: 0.1,
                    }}
                >
                    S
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: sx ? 28 : 36,
                        fontWeight: 500,
                        color: '#616161c6',
                        textShadow: '#000000 0 0 1.25px',
                    }}
                >
                    A
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: sx ? 28 : 36,
                        fontWeight: 500,
                        color: '#616161c6',
                        textShadow: '#000000 0 0 1.25px',
                    }}
                >
                    L
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: sx ? 28 : 36,
                        fontWeight: 500,
                        color: '#616161c6',
                        textShadow: '#000000 0 0 1.25px',
                    }}
                >
                    E
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: sx ? 28 : 36,
                        fontWeight: 500,
                        color: '#616161c6',
                        textShadow: '#000000 0 0 1.25px',
                    }}
                >
                    S
                </Typography>

                <Typography
                    sx={{
                        ml: 1,
                        fontFamily: 'Young Serif',
                        fontSize: sx ? 28 : 36,
                        fontWeight: 600,
                        color: '#161616',
                        textShadow: '#0000006a 0 0 2px',
                        mr: 0.1,
                    }}
                >
                    N
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: sx ? 28 : 36,
                        fontWeight: 500,
                        color: '#616161c6',
                        textShadow: '#000000 0 0 1.25px',
                    }}
                >
                    E
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: sx ? 28 : 36,
                        fontWeight: 500,
                        color: '#616161c6',
                        textShadow: '#000000 0 0 1.25px',
                    }}
                >
                    S
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: sx ? 28 : 36,
                        fontWeight: 500,
                        color: '#616161c6',
                        textShadow: '#000000 0 0 1.25px',
                    }}
                >
                    T
                </Typography>
            </Box>
        </Box>
    );
};

export default LandingLogo;
