import InfoDialog from 'components/organisms/Modals/InfoDialog';

const SomethingWentWrong = ({ isOpen, setIsOpen, string }) => {
    if (!isOpen) return null;

    return (
        <>
            <InfoDialog
                variant="error"
                link={null}
                string={string}
                onClose={() => {
                    setIsOpen(null);
                }}
                title={null}
                fullWidth
                onSubmit={() => {
                    setIsOpen(null);
                }}
                description={string?.something_went_wrong}
                closeAvailable
                component="bad request"
                content={null}
            />
        </>
    );
};

export default SomethingWentWrong;
