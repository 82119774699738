import { useEffect, useState } from 'react';
import { LONG_PHRASES } from 'dataBase/translations/long_phrases.js';
import { LANGS } from 'dataBase/translations/landing_page';
import { FORM_CREATE } from 'dataBase/translations/create_form';

interface Props {
    lang: string;
}
export interface LanguageDataInterface {
    code: string;
    string: Record<string, any>;
}

export interface LangResInterface {
    currentLanguage: LanguageDataInterface;
}

export const useGetLanguage = ({ lang }: Props): LangResInterface => {
    const [currentLanguage, setCurrentLanguage] = useState({ code: '', string: {} });

    const langList = [
        {
            code: 'en',
            string: {
                ...FORM_CREATE?.en,
                ...LONG_PHRASES?.en,
                ...LANGS?.en,
                hero_title: 'Create and Promote Online Catalogs.',
                hero_description:
                    'offers a full range of services for creating and promoting online catalogs to help you attract more customers and increase sales',
                create_catalog: 'Create a Catalog',
                enter_phone_or_email: 'Phone number or email',
                request: 'Request',
                settings: 'Settings',
                start: 'Start',
                advertising: 'Advertising',
                create_title: 'Create an Online Catalog — 4 Simple Steps',
                contacts: 'Contacts',
                message: 'Message',
                feedback_form: 'Feedback form',
                email: 'Email',
                send: 'Send',
                phone_number: 'Phone number',
                clear_form: 'Clear form',
                request_sended_successfully: 'Request successfully sent',
                ok: 'Ok',
                language: 'Language',
                view_sample: 'View Sample',
                we_will_answer_soon: 'We will contact you as soon as possible',
                scope_of_application: 'Scope of Application',
                social_media_commerce: 'Social Media Commerce',
                social_media_commerce_description:
                    'Integration of your catalog with popular social networks such as Instagram, Facebook, TikTok, and Pinterest will help increase your audience and sales.',
                retail_and_small_business: 'Retail and Small Business',
                retail_and_small_business_description:
                    'Your online catalog provides a simple and convenient way for both individual customers and small companies to shop.',
                wholesale_and_medium_business: 'Wholesale and Medium Business',
                wholesale_and_medium_business_description:
                    'Your online catalog facilitates the ordering process for wholesale buyers and provides a convenient way to showcase products and services for corporate clients.',
                b2b: 'B2B',
                b2b_description:
                    'An online catalog helps companies in the B2B segment showcase their products and services, simplifying the search and ordering process for manufacturers and large suppliers.',
                social_media_promotion: 'Social Media Promotion',
                social_media_promotion_description:
                    'Promotion of the catalog in popular social networks to attract new customers and increase sales.',
                google_promotion: 'Google Promotion',
                google_promotion_description:
                    'Advertising and promotion of the catalog in the Google search engine to increase online visibility and attract the target audience.',
                email_marketing_and_mailings: 'Email Marketing and Mailings',
                email_marketing_and_mailings_description:
                    'Using email marketing and mailings to promote the catalog and attract the attention of potential customers.',
                collaboration_with_bloggers: 'Collaboration with Bloggers',
                collaboration_with_bloggers_description:
                    'Collaboration with bloggers or influencers to promote products from the catalog through their social media and blogs.',
                support_and_development: 'Support and Development',
                round_the_clock_support: 'Round-the-clock Support:',
                regular_updates_and_improvements: 'Regular Updates and Improvements:',
                performance_monitoring: 'Performance Monitoring:',
                data_security_and_protection: 'Data Security and Protection:',
                any_language: 'Embrace Any Language',
                any_currency: 'Accept Any Currency',
                no_limitations: 'Unlimited Product Options',
                unique_url: 'Unique Site, Unique URL',
            },
        },

        {
            code: 'et',
            string: {
                ...FORM_CREATE?.et,
                ...LONG_PHRASES?.et,
                ...LANGS?.et,
                hero_title: 'Loo ja reklaami veebikatalooge.',
                hero_description:
                    'pakub täielikku teenuste valikut veebikataloogide loomiseks ja reklaamimiseks, et aidata teil meelitada rohkem kliente ja suurendada müüki',
                create_catalog: 'Loo kataloog',
                enter_phone_or_email: 'Telefoninumber või e-post',
                request: 'Taotlus',
                settings: 'Seaded',
                start: 'Alusta',
                advertising: 'Reklaam',
                create_title: 'Loo veebikataloog – 4 lihtsat sammu',
                contacts: 'Kontaktid',
                message: 'Sõnum',
                feedback_form: 'Tagasiside vorm',
                email: 'E-post',
                send: 'Saada',
                phone_number: 'Telefoninumber',
                clear_form: 'Tühjenda vorm',
                request_sended_successfully: 'Taotlus edukalt saadetud',
                ok: 'OK',
                language: 'Keel',
                view_sample: 'Vaata näidist',
                we_will_answer_soon: 'Võtame teiega peagi ühendust',
                scope_of_application: 'Kasutusala',
                social_media_commerce: 'Sotsiaalmeedia kaubandus',
                social_media_commerce_description:
                    'Teie kataloogi integreerimine populaarsete sotsiaalvõrgustikega nagu Instagram, Facebook, TikTok ja Pinterest aitab suurendada teie publikut ja müüki.',
                retail_and_small_business: 'Jaekaubandus ja väikeettevõtted',
                retail_and_small_business_description:
                    'Teie veebikataloog pakub lihtsat ja mugavat viisi ostlemiseks nii individuaalsetele klientidele kui ka väikestele ettevõtetele.',
                wholesale_and_medium_business: 'Hulgikaubandus ja keskmised ettevõtted',
                wholesale_and_medium_business_description:
                    'Teie veebikataloog lihtsustab hulgimüüjatele tellimisprotsessi ja pakub mugavat viisi toodete ja teenuste tutvustamiseks äriklientidele.',
                b2b: 'B2B',
                b2b_description:
                    'Veebikataloog aitab B2B segmendi ettevõtetel tutvustada oma tooteid ja teenuseid, lihtsustades tootjate ja suurte tarnijate otsingu- ja tellimisprotsessi.',
                social_media_promotion: 'Sotsiaalmeedia reklaam',
                social_media_promotion_description:
                    'Kataloogi reklaamimine populaarsetes sotsiaalvõrgustikes uute klientide meelitamiseks ja müügi suurendamiseks.',
                google_promotion: 'Google reklaam',
                google_promotion_description:
                    'Kataloogi reklaamimine ja reklaamimine Google otsingumootoris, et suurendada veebinähtavust ja meelitada sihtrühma.',
                email_marketing_and_mailings: 'E-posti turundus ja postitused',
                email_marketing_and_mailings_description:
                    'E-posti turunduse ja postituste kasutamine kataloogi reklaamimiseks ja potentsiaalsete klientide tähelepanu tõmbamiseks.',
                collaboration_with_bloggers: 'Koostöö blogijatega',
                collaboration_with_bloggers_description:
                    'Koostöö blogijate või mõjutajatega, et reklaamida kataloogi tooteid nende sotsiaalmeedia ja blogide kaudu.',
                support_and_development: 'Tugi ja areng',
                round_the_clock_support: 'Ööpäevaringne tugi:',
                regular_updates_and_improvements: 'Regulaarsed uuendused ja täiustused:',
                performance_monitoring: 'Tulemuslikkuse jälgimine:',
                data_security_and_protection: 'Andmete turvalisus ja kaitse:',
                any_language: 'Toetab mis tahes keelt',
                any_currency: 'Toetab mis tahes valuutat',
                no_limitations: 'Piiranguteta tootevalik',
                unique_url: 'Oma sait, oma URL',
            },
        },

        {
            code: 'lv',
            string: {
                ...FORM_CREATE?.lv,
                ...LONG_PHRASES?.lv,
                ...LANGS.lv,
                hero_title: 'Izveidojiet un reklamējiet tiešsaistes katalogus.',
                hero_description:
                    'piedāvā pilnu pakalpojumu klāstu tiešsaistes katalogu izveidei un popularizēšanai, lai palīdzētu jums piesaistīt vairāk klientu un palielināt pārdošanu',
                create_catalog: 'Izveidot katalogu',
                enter_phone_or_email: 'Tālruņa numurs vai e-pasts',
                request: 'Pieprasījums',
                settings: 'Iestatījumi',
                start: 'Sākt',
                advertising: 'Reklāma',
                create_title: 'Izveidojiet tiešsaistes katalogu — 4 vienkārši soļi',
                contacts: 'Kontakti',
                message: 'Ziņojums',
                feedback_form: 'Atsauksmju veidlapa',
                email: 'E-pasts',
                send: 'Sūtīt',
                phone_number: 'Tālruņa numurs',
                clear_form: 'Notīrīt veidlapu',
                request_sended_successfully: 'Pieprasījums veiksmīgi nosūtīts',
                ok: 'Labi',
                language: 'Valoda',
                view_sample: 'Skatīt paraugu',
                we_will_answer_soon: 'Mēs sazināsimies ar jums pēc iespējas ātrāk',
                scope_of_application: 'Pielietojuma joma',
                social_media_commerce: 'Sociālo mediju tirdzniecība',
                social_media_commerce_description:
                    'Jūsu kataloga integrēšana ar populāriem sociālajiem tīkliem, piemēram, Instagram, Facebook, TikTok un Pinterest, palīdzēs palielināt jūsu auditoriju un pārdošanu.',
                retail_and_small_business: 'Mazumtirdzniecība un mazie uzņēmumi',
                retail_and_small_business_description:
                    'Jūsu tiešsaistes katalogs nodrošina vienkāršu un ērtu veidu, kā iepirkties gan individuāliem klientiem, gan mazajiem uzņēmumiem.',
                wholesale_and_medium_business: 'Vairumtirdzniecība un vidēji uzņēmumi',
                wholesale_and_medium_business_description:
                    'Jūsu tiešsaistes katalogs atvieglo pasūtīšanas procesu vairumtirgotājiem un nodrošina ērtu veidu, kā korporatīvajiem klientiem piedāvāt produktus un pakalpojumus.',
                b2b: 'B2B',
                b2b_description:
                    'Tiešsaistes katalogs palīdz B2B segmenta uzņēmumiem piedāvāt savus produktus un pakalpojumus, atvieglojot ražotāju un lielo piegādātāju meklēšanas un pasūtīšanas procesu.',
                social_media_promotion: 'Sociālo mediju reklamēšana',
                social_media_promotion_description:
                    'Kataloga popularizēšana populāros sociālajos tīklos, lai piesaistītu jaunus klientus un palielinātu pārdošanu.',
                google_promotion: 'Google reklamēšana',
                google_promotion_description:
                    'Kataloga reklamēšana un popularizēšana Google meklētājā, lai palielinātu tiešsaistes redzamību un piesaistītu mērķauditoriju.',
                email_marketing_and_mailings: 'E-pasta mārketings un sūtījumi',
                email_marketing_and_mailings_description:
                    'Izmantojot e-pasta mārketingu un sūtījumus, lai reklamētu katalogu un piesaistītu potenciālo klientu uzmanību.',
                collaboration_with_bloggers: 'Sadarbība ar blogeriem',
                collaboration_with_bloggers_description:
                    'Sadarbība ar blogeriem vai ietekmētājiem, lai reklamētu katalogā esošos produktus viņu sociālajos tīklos un blogos.',
                support_and_development: 'Atbalsts un attīstība',
                round_the_clock_support: 'Diennakts atbalsts:',
                regular_updates_and_improvements: 'Regulāri atjauninājumi un uzlabojumi:',
                performance_monitoring: 'Veiktspējas uzraudzība:',
                data_security_and_protection: 'Datu drošība un aizsardzība:',
                any_language: 'Atbalsta jebkuru valodu',
                any_currency: 'Atbalsta jebkuru valūtu',
                no_limitations: 'Bez ierobežojumiem produktu skaitam',
                unique_url: 'Piederīga vietne, piederīgs URL',
            },
        },
        {
            code: 'lt',
            string: {
                ...FORM_CREATE?.lt,
                ...LONG_PHRASES?.lt,
                ...LANGS?.lt,
                hero_title: 'Kurti ir reklamuoti internetinius katalogus.',
                hero_description:
                    'siūlo pilną paslaugų spektrą internetinių katalogų kūrimui ir reklamavimui, siekiant padėti jums pritraukti daugiau klientų ir padidinti pardavimus',
                create_catalog: 'Sukurti katalogą',
                enter_phone_or_email: 'Telefono numeris arba el. paštas',
                request: 'Prašymas',
                settings: 'Nustatymai',
                start: 'Pradėti',
                advertising: 'Reklama',
                create_title: 'Sukurti internetinį katalogą — 4 paprasti žingsniai',
                contacts: 'Kontaktai',
                message: 'Pranešimas',
                feedback_form: 'Atsiliepimų forma',
                email: 'El. paštas',
                send: 'Siųsti',
                phone_number: 'Telefono numeris',
                clear_form: 'Išvalyti formą',
                request_sended_successfully: 'Prašymas sėkmingai išsiųstas',
                ok: 'Gerai',
                language: 'Kalba',
                view_sample: 'Peržiūrėti pavyzdį',
                we_will_answer_soon: 'Mes susisieksime su jumis kuo greičiau',
                scope_of_application: 'Taikymo sritis',
                social_media_commerce: 'Socialinių medijų prekyba',
                social_media_commerce_description:
                    'Jūsų katalogo integracija su populiariais socialiniais tinklais, tokiais kaip Instagram, Facebook, TikTok ir Pinterest, padės padidinti jūsų auditoriją ir pardavimus.',
                retail_and_small_business: 'Mažmeninė prekyba ir mažos įmonės',
                retail_and_small_business_description:
                    'Jūsų internetinis katalogas suteikia paprastą ir patogų būdą apsipirkti tiek individualiems klientams, tiek mažoms įmonėms.',
                wholesale_and_medium_business: 'Didmeninė prekyba ir vidutinės įmonės',
                wholesale_and_medium_business_description:
                    'Jūsų internetinis katalogas palengvina užsakymo procesą didmenininkams ir suteikia patogų būdą pristatyti produktus ir paslaugas verslo klientams.',
                b2b: 'B2B',
                b2b_description:
                    'Internetinis katalogas padeda B2B segmento įmonėms pristatyti savo produktus ir paslaugas, palengvindamas gamintojų ir didelių tiekėjų paieškos ir užsakymo procesą.',
                social_media_promotion: 'Socialinių medijų reklama',
                social_media_promotion_description:
                    'Katalogo reklama populiariuose socialiniuose tinkluose, siekiant pritraukti naujų klientų ir padidinti pardavimus.',
                google_promotion: 'Google reklama',
                google_promotion_description:
                    'Katalogo reklama ir reklama „Google“ paieškos sistemoje, siekiant padidinti internetinį matomumą ir pritraukti tikslinę auditoriją.',
                email_marketing_and_mailings: 'El. pašto rinkodara ir laiškai',
                email_marketing_and_mailings_description:
                    'Naudojant el. pašto rinkodarą ir laiškus katalogo reklamai ir potencialių klientų dėmesio pritraukimui.',
                collaboration_with_bloggers: 'Bendradarbiavimas su tinklaraštininkais',
                collaboration_with_bloggers_description:
                    'Bendradarbiavimas su tinklaraštininkais ar influenceriais, siekiant reklamuoti kataloge esančius produktus per jų socialinius tinklus ir tinklaraščius.',
                support_and_development: 'Parama ir plėtra',
                round_the_clock_support: '24 valandų parama:',
                regular_updates_and_improvements: 'Reguliarūs atnaujinimai ir patobulinimai:',
                performance_monitoring: 'Veiklos stebėsena:',
                data_security_and_protection: 'Duomenų saugumas ir apsauga:',
                any_language: 'Palaiko bet kurį kalbą',
                any_currency: 'Palaiko bet kurį valiutą',
                no_limitations: 'Neriboti prekių kiekiai',
                unique_url: 'Savo svetainė, savas URL',
            },
        },

        {
            code: 'ru',
            string: {
                ...FORM_CREATE?.ru,
                ...LANGS?.ru,
                ...LONG_PHRASES?.ru,
                hero_title: 'Создание и продвижение интернет-каталогов.',
                hero_description:
                    'предлагает полный спектр услуг по созданию и продвижению интернет-каталогов, чтобы помочь вам привлекать больше клиентов и увеличивать продажи',
                create_catalog: 'Создать каталог',
                enter_phone_or_email: 'Номер телефона или email',
                request: 'Заявка',
                settings: 'Настройки',
                start: 'Запуск',
                advertising: 'Реклама',
                create_title: 'Создать интернет-каталог — 4 простых шага',
                contacts: 'Контакты',
                message: 'Сообщение',
                feedback_form: 'Форма обратной связи',
                email: 'Email',
                phone_number: 'Номер телефона',
                send: 'Отправить',
                clear_form: 'Очистить форму',
                request_sended_successfully: 'Запрос успешно отправлен',
                ok: 'Ок',
                language: 'Язык',
                view_sample: 'Посмотреть образец',
                we_will_answer_soon: 'Мы свяжемся с вами в ближайшее время',
                scope_of_application: 'Область применения',
                social_media_commerce: 'Торговля в соцсетях',
                social_media_commerce_description:
                    'Интеграция вашего каталога с популярными социальными сетями, такими как Instagram, Facebook, TikTok и Pinterest, поможет увеличить вашу аудиторию и продажи.',
                retail_and_small_business: 'Розничная торговля и малый бизнес',
                retail_and_small_business_description:
                    'Ваш интернет-каталог предоставляет простой и удобный способ покупки как для индивидуальных клиентов, так и для малых компаний.',
                wholesale_and_medium_business: 'Оптовая торговля и средний бизнес',
                wholesale_and_medium_business_description:
                    'Ваш интернет-каталог облегчает процесс заказа для оптовых покупателей и предоставляет удобный способ демонстрации продукции и услуг для корпоративных клиентов.',
                b2b: 'B2B',
                b2b_description:
                    'Интернет-каталог поможет компаниям в сегменте B2B демонстрировать свою продукцию и услуги, упрощая процесс поиска и заказа для производителей и крупных поставщиков.',
                social_media_promotion: 'Продвижение в социальных сетях',
                social_media_promotion_description:
                    'Продвижение каталога в популярных социальных сетях для привлечения новых клиентов и увеличения продаж.',
                google_promotion: 'Продвижение в Google',
                google_promotion_description:
                    'Реклама и продвижение каталога в поисковой системе Google для увеличения онлайн-видимости и привлечения целевой аудитории.',
                email_marketing_and_mailings: 'Email-маркетинг и рассылки',
                email_marketing_and_mailings_description:
                    'Использование email-маркетинга и рассылок для продвижения каталога и привлечения внимания потенциальных клиентов.',
                collaboration_with_bloggers: 'Сотрудничество с блогерами',
                collaboration_with_bloggers_description:
                    'Сотрудничество с блогерами или влиятелями для продвижения продукции из каталога через их социальные медиа и блоги.',
                support_and_development: 'Поддержка и развитие',
                round_the_clock_support: 'Круглосуточная поддержка:',
                regular_updates_and_improvements: 'Регулярные обновления и улучшения:',
                performance_monitoring: 'Мониторинг производительности:',
                data_security_and_protection: 'Безопасность и защита данных:',
                any_language: 'Поддержка любого языка',
                any_currency: 'Поддержка любой валюты',
                no_limitations: 'Отсутствие ограничений по количеству товаров',
                unique_url: 'Собственный сайт, собственный URL',
            },
        },

        {
            code: 'ua',

            string: {
                ...FORM_CREATE?.ua,
                ...LANGS?.ua,
                ...LONG_PHRASES?.ua,
                hero_title: 'Створення та Продаж Онлайн-Каталогів.',
                hero_description:
                    'пропонує повний спектр послуг зі створення та продажу онлайн-каталогів, щоб допомогти вам залучити більше клієнтів та збільшити продажі',
                create_catalog: 'Створити каталог',
                enter_phone_or_email: 'Номер телефону або email',
                request: 'Запит',
                settings: 'Налаштування',
                start: 'Початок',
                advertising: 'Реклама',
                create_title: 'Створення Онлайн-Каталогу - 4 Прості Кроки',
                contacts: 'Контакти',
                message: 'Повідомлення',
                feedback_form: "Форма зворотнього зв'язку",
                email: 'Електронна пошта',
                send: 'Надіслати',
                phone_number: 'Номер телефону',
                clear_form: 'Очистити форму',
                request_sended_successfully: 'Запит успішно відправлено',
                ok: 'Ok',
                language: 'Мова',
                view_sample: 'Переглянути зразок',
                we_will_answer_soon: "Ми зв'яжемося з вами найближчим часом",
                scope_of_application: 'Сфера застосування',
                social_media_commerce: 'Торгівля в соцмережах',
                social_media_commerce_description:
                    'Інтеграція вашого каталогу з популярними соціальними мережами, такими як Instagram, Facebook, TikTok та Pinterest, допоможе збільшити вашу аудиторію та продажі.',
                retail_and_small_business: 'Роздрібна торгівля та малі підприємства',
                retail_and_small_business_description:
                    'Ваш онлайн-каталог надає простий та зручний спосіб для як індивідуальних клієнтів, так і малих компаній, щоб робити покупки.',
                wholesale_and_medium_business: 'Оптова торгівля та середні підприємства',
                wholesale_and_medium_business_description:
                    'Ваш онлайн-каталог спрощує процес замовлення для оптових покупців та надає зручний спосіб для демонстрації продукції та послуг корпоративним клієнтам.',
                b2b: 'B2B',
                b2b_description:
                    'Онлайн-каталог допомагає компаніям у сегменті B2B демонструвати свою продукцію та послуги, спрощуючи процес пошуку та замовлення для виробників та великих постачальників.',
                social_media_promotion: 'Продаж в соцмережах',
                social_media_promotion_description:
                    'Продаж каталогу в популярних соціальних мережах для привертання нових клієнтів та збільшення продажів.',
                google_promotion: 'Реклама в Google',
                google_promotion_description:
                    'Реклама та просування каталогу в пошуковій системі Google для збільшення онлайн-видимості та привертання цільової аудиторії.',
                email_marketing_and_mailings: 'Email-маркетинг та розсилки',
                email_marketing_and_mailings_description:
                    'Використання email-маркетингу та розсилок для просування каталогу та привертання уваги потенційних клієнтів.',
                collaboration_with_bloggers: 'Співпраця з блогерами',
                collaboration_with_bloggers_description:
                    'Співпраця з блогерами або впливовими особистостями для просування продукції з каталогу через їх соціальні мережі та блоги.',
                support_and_development: 'Підтримка та розвиток',
                round_the_clock_support: 'Цілодобова підтримка:',
                regular_updates_and_improvements: 'Регулярні оновлення та покращення:',
                performance_monitoring: 'Моніторинг продуктивності:',
                data_security_and_protection: 'Безпека та захист даних:',
                any_language: 'Підтримка будь-якої мови',
                any_currency: 'Підтримка будь-якої валюти',
                no_limitations: 'Відсутність обмежень на кількість товарів',
                unique_url: 'Власний сайт, власний URL',
            },
        },
        {
            code: 'pl',
            string: {
                ...FORM_CREATE?.pl,
                ...LANGS?.pl,
                ...LONG_PHRASES?.pl,
                hero_title: 'Tworzenie i Promowanie Katalogów Online.',
                hero_description:
                    'oferuje pełen zakres usług dotyczących tworzenia i promowania katalogów online, aby pomóc Ci przyciągnąć więcej klientów i zwiększyć sprzedaż',
                create_catalog: 'Utwórz katalog',
                enter_phone_or_email: 'Numer telefonu lub email',
                request: 'Żądanie',
                settings: 'Ustawienia',
                start: 'Rozpocznij',
                advertising: 'Reklama',
                create_title: 'Utwórz Katalog Online - 4 Proste Krok',
                contacts: 'Kontakty',
                message: 'Wiadomość',
                feedback_form: 'Formularz opinii',
                email: 'Email',
                send: 'Wyślij',
                phone_number: 'Numer telefonu',
                clear_form: 'Wyczyść formularz',
                request_sended_successfully: 'Żądanie zostało pomyślnie wysłane',
                ok: 'Ok',
                language: 'Język',
                view_sample: 'Zobacz przykład',
                we_will_answer_soon: 'Skontaktujemy się z Tobą tak szybko, jak to możliwe',
                scope_of_application: 'Zakres Zastosowania',
                social_media_commerce: 'Handel w mediach społecznościowych',
                social_media_commerce_description:
                    'Integracja katalogu z popularnymi sieciami społecznościowymi, takimi jak Instagram, Facebook, TikTok i Pinterest, pomoże zwiększyć Twoją publiczność i sprzedaż.',
                retail_and_small_business: 'Sprzedaż detaliczna i małe przedsiębiorstwa',
                retail_and_small_business_description:
                    'Twój katalog online zapewnia prosty i wygodny sposób zarówno dla klientów indywidualnych, jak i małych firm, aby robić zakupy.',
                wholesale_and_medium_business: 'Sprzedaż hurtowa i średnie przedsiębiorstwa',
                wholesale_and_medium_business_description:
                    'Twój katalog online ułatwia proces zamawiania dla klientów hurtowych i zapewnia wygodny sposób prezentacji produktów i usług dla klientów korporacyjnych.',
                b2b: 'B2B',
                b2b_description:
                    'Katalog online pomaga firmom w segmencie B2B prezentować swoje produkty i usługi, upraszczając proces wyszukiwania i zamawiania dla producentów i dużych dostawców.',
                social_media_promotion: 'Promocja w mediach społecznościowych',
                social_media_promotion_description:
                    'Promocja katalogu w popularnych sieciach społecznościowych w celu przyciągnięcia nowych klientów i zwiększenia sprzedaży.',
                google_promotion: 'Promocja w Google',
                google_promotion_description:
                    'Reklama i promocja katalogu w wyszukiwarce Google w celu zwiększenia widoczności online i przyciągnięcia docelowej grupy odbiorców.',
                email_marketing_and_mailings: 'Email marketing i wysyłki',
                email_marketing_and_mailings_description:
                    'Wykorzystanie email marketingu i wysyłek w celu promowania katalogu i przyciągnięcia uwagi potencjalnych klientów.',
                collaboration_with_bloggers: 'Współpraca z blogerami',
                collaboration_with_bloggers_description:
                    'Współpraca z blogerami lub influencerami w celu promowania produktów z katalogu za pośrednictwem ich mediów społecznościowych i blogów.',
                support_and_development: 'Wsparcie i rozwój',
                round_the_clock_support: 'Wsparcie całodobowe:',
                regular_updates_and_improvements: 'Regularne aktualizacje i ulepszenia:',
                performance_monitoring: 'Monitorowanie wydajności:',
                any_language: 'Obsługa dowolnego języka',
                any_currency: 'Obsługa dowolnej waluty',
                no_limitations: 'Brak ograniczeń dotyczących ilości produktów',
                unique_url: 'Własna witryna, własny URL',
                data_security_and_protection: 'Bezpieczeństwo danych:',
            },
        },
        {
            code: 'cz',
            string: {
                ...FORM_CREATE?.cz,
                ...LANGS?.cz,
                ...LONG_PHRASES?.cz,
                hero_title: 'Vytvořte a propagujte online katalogy.',
                hero_description:
                    'nabízí kompletní sortiment služeb pro vytváření a propagaci online katalogů, které vám pomohou přilákat více zákazníků a zvýšit prodeje',
                create_catalog: 'Vytvořit katalog',
                enter_phone_or_email: 'Telefonní číslo nebo e-mail',
                request: 'Žádost',
                settings: 'Nastavení',
                start: 'Spustit',
                advertising: 'Reklama',
                create_title: 'Vytvořte online katalog - 4 jednoduché kroky',
                contacts: 'Kontakty',
                message: 'Zpráva',
                feedback_form: 'Formulář zpětné vazby',
                email: 'E-mail',
                send: 'Odeslat',
                phone_number: 'Telefonní číslo',
                clear_form: 'Vymazat formulář',
                request_sended_successfully: 'Žádost úspěšně odeslána',
                ok: 'Ok',
                language: 'Jazyk',
                view_sample: 'Zobrazit vzorek',
                we_will_answer_soon: 'Ozveme se vám co nejdříve',
                scope_of_application: 'Rozsah použití',
                social_media_commerce: 'Obchod na sociálních sítích',
                social_media_commerce_description:
                    'Integrace vašeho katalogu se populárními sociálními sítěmi jako jsou Instagram, Facebook, TikTok a Pinterest pomůže zvýšit vaši cílovou skupinu a prodeje.',
                retail_and_small_business: 'Malooobchod a malé podnikání',
                retail_and_small_business_description:
                    'Váš online katalog poskytuje jednoduchý a pohodlný způsob nákupu jak pro individuální zákazníky, tak pro malé podniky.',
                wholesale_and_medium_business: 'Velkoobchod a střední podnikání',
                wholesale_and_medium_business_description:
                    'Váš online katalog usnadňuje proces objednávání pro velkoobchodní kupce a poskytuje pohodlný způsob prezentace produktů a služeb pro firemní klienty.',
                b2b: 'B2B',
                b2b_description:
                    'Online katalog pomáhá firmám v segmentu B2B prezentovat jejich produkty a služby, zjednodušující proces hledání a objednávání pro výrobce a velké dodavatele.',
                social_media_promotion: 'Propagace na sociálních sítích',
                social_media_promotion_description:
                    'Propagace katalogu na populárních sociálních sítích pro přilákání nových zákazníků a zvýšení prodejů.',
                google_promotion: 'Propagace na Googlu',
                google_promotion_description:
                    'Reklama a propagace katalogu ve vyhledávači Google pro zvýšení online viditelnosti a přilákání cílové skupiny.',
                email_marketing_and_mailings: 'E-mailový marketing a rozesílky',
                email_marketing_and_mailings_description:
                    'Využití e-mailového marketingu a rozesílky k propagaci katalogu a přitáhnutí pozornosti potenciálních zákazníků.',
                collaboration_with_bloggers: 'Spolupráce s blogery',
                collaboration_with_bloggers_description:
                    'Spolupráce s blogery nebo influencery k propagaci produktů z katalogu prostřednictvím jejich sociálních médií a blogů.',
                support_and_development: 'Podpora a vývoj',
                round_the_clock_support: 'Non-stop podpora:',
                regular_updates_and_improvements: 'Pravidelné aktualizace a vylepšení:',
                performance_monitoring: 'Monitorování výkonu:',
                data_security_and_protection: 'Zabezpečení a ochrana dat:',
                any_language: 'Podpora jakéhokoli jazyka',
                any_currency: 'Podpora jakékoli měny',
                no_limitations: 'Žádné omezení počtu produktů',
                unique_url: 'Vlastní stránka, vlastní URL',
            },
        },
        {
            code: 'es',
            string: {
                ...FORM_CREATE?.es,
                ...LANGS?.es,
                ...LONG_PHRASES?.es,
                hero_title: 'Crea y Promociona Catálogos en Línea.',
                hero_description:
                    'ofrece una gama completa de servicios para crear y promocionar catálogos en línea para ayudarte a atraer más clientes y aumentar las ventas',
                create_catalog: 'Crear un Catálogo',
                enter_phone_or_email: 'Número de teléfono o correo electrónico',
                request: 'Solicitar',
                settings: 'Configuración',
                start: 'Comenzar',
                advertising: 'Publicidad',
                create_title: 'Crear un Catálogo en Línea — 4 Pasos Sencillos',
                contacts: 'Contactos',
                message: 'Mensaje',
                feedback_form: 'Formulario de Comentarios',
                email: 'Correo electrónico',
                send: 'Enviar',
                phone_number: 'Número de teléfono',
                clear_form: 'Limpiar formulario',
                request_sended_successfully: 'Solicitud enviada correctamente',
                ok: 'Ok',
                language: 'Idioma',
                view_sample: 'Ver Muestra',
                we_will_answer_soon: 'Nos pondremos en contacto contigo lo antes posible',
                scope_of_application: 'Ámbito de Aplicación',
                social_media_commerce: 'Comercio en Redes Sociales',
                social_media_commerce_description:
                    'La integración de tu catálogo con redes sociales populares como Instagram, Facebook, TikTok y Pinterest ayudará a aumentar tu audiencia y ventas.',
                retail_and_small_business: 'Venta al por Menor y Pequeñas Empresas',
                retail_and_small_business_description:
                    'Tu catálogo en línea proporciona una forma simple y conveniente para que tanto clientes individuales como pequeñas empresas compren.',
                wholesale_and_medium_business: 'Venta al por Mayor y Medianas Empresas',
                wholesale_and_medium_business_description:
                    'Tu catálogo en línea facilita el proceso de pedido para compradores al por mayor y proporciona una forma conveniente de mostrar productos y servicios para clientes corporativos.',
                b2b: 'B2B',
                b2b_description:
                    'Un catálogo en línea ayuda a las empresas en el segmento B2B a mostrar sus productos y servicios, simplificando el proceso de búsqueda y pedido para fabricantes y grandes proveedores.',
                social_media_promotion: 'Promoción en Redes Sociales',
                social_media_promotion_description:
                    'Promoción del catálogo en redes sociales populares para atraer nuevos clientes y aumentar las ventas.',
                google_promotion: 'Promoción en Google',
                google_promotion_description:
                    'Publicidad y promoción del catálogo en el motor de búsqueda de Google para aumentar la visibilidad en línea y atraer al público objetivo.',
                email_marketing_and_mailings: 'Marketing por Correo Electrónico y Envíos',
                email_marketing_and_mailings_description:
                    'Uso de marketing por correo electrónico y envíos para promocionar el catálogo y llamar la atención de clientes potenciales.',
                collaboration_with_bloggers: 'Colaboración con Bloggers',
                collaboration_with_bloggers_description:
                    'Colaboración con bloggers o influencers para promocionar productos del catálogo a través de sus redes sociales y blogs.',
                support_and_development: 'Soporte y Desarrollo',
                round_the_clock_support: 'Soporte las 24 horas:',
                regular_updates_and_improvements: 'Actualizaciones y Mejoras Regulares:',
                performance_monitoring: 'Monitoreo del Rendimiento:',
                data_security_and_protection: 'Seguridad y Protección de Datos:',
                any_language: 'Acepta Cualquier Idioma',
                any_currency: 'Acepta Cualquier Moneda',
                no_limitations: 'Opciones de Producto Ilimitadas',
                unique_url: 'Sitio Único, URL Única',
            },
        },
        {
            code: 'fr',
            string: {
                ...FORM_CREATE?.fr,
                ...LANGS?.fr,
                ...LONG_PHRASES?.fr,
                hero_title: 'Créez et promouvez des catalogues en ligne.',
                hero_description:
                    'offre une gamme complète de services pour la création et la promotion de catalogues en ligne afin de vous aider à attirer plus de clients et à augmenter les ventes',
                create_catalog: 'Créer un catalogue',
                enter_phone_or_email: 'Numéro de téléphone ou e-mail',
                request: 'Demande',
                settings: 'Paramètres',
                start: 'Commencer',
                advertising: 'Publicité',
                create_title: 'Créer un catalogue en ligne — 4 étapes simples',
                contacts: 'Contacts',
                message: 'Message',
                feedback_form: 'Formulaire de rétroaction',
                email: 'Email',
                send: 'Envoyer',
                phone_number: 'Numéro de téléphone',
                clear_form: 'Effacer le formulaire',
                request_sended_successfully: 'Demande envoyée avec succès',
                ok: 'Ok',
                language: 'Langue',
                view_sample: 'Voir un échantillon',
                we_will_answer_soon: 'Nous vous contacterons dès que possible',
                scope_of_application: "Domaine d'application",
                social_media_commerce: 'Commerce sur les réseaux sociaux',
                social_media_commerce_description:
                    "L'intégration de votre catalogue avec des réseaux sociaux populaires tels qu'Instagram, Facebook, TikTok et Pinterest aidera à augmenter votre audience et vos ventes.",
                retail_and_small_business: 'Commerce de détail et petites entreprises',
                retail_and_small_business_description:
                    'Votre catalogue en ligne offre un moyen simple et pratique pour les clients individuels et les petites entreprises de faire des achats.',
                wholesale_and_medium_business: 'Commerce de gros et moyennes entreprises',
                wholesale_and_medium_business_description:
                    'Votre catalogue en ligne facilite le processus de commande pour les acheteurs en gros et offre un moyen pratique de présenter des produits et services aux clients professionnels.',
                b2b: 'B2B',
                b2b_description:
                    'Un catalogue en ligne aide les entreprises du segment B2B à présenter leurs produits et services, simplifiant le processus de recherche et de commande pour les fabricants et les grands fournisseurs.',
                social_media_promotion: 'Promotion sur les réseaux sociaux',
                social_media_promotion_description:
                    'Promotion du catalogue sur les réseaux sociaux populaires pour attirer de nouveaux clients et augmenter les ventes.',
                google_promotion: 'Promotion sur Google',
                google_promotion_description:
                    'Publicité et promotion du catalogue dans le moteur de recherche Google pour augmenter la visibilité en ligne et attirer le public cible.',
                email_marketing_and_mailings: 'Marketing par e-mail et envois postaux',
                email_marketing_and_mailings_description:
                    "Utilisation du marketing par e-mail et d'envois postaux pour promouvoir le catalogue et attirer l'attention des clients potentiels.",
                collaboration_with_bloggers: 'Collaboration avec les blogueurs',
                collaboration_with_bloggers_description:
                    'Collaboration avec des blogueurs ou des influenceurs pour promouvoir les produits du catalogue via leurs réseaux sociaux et leurs blogs.',
                support_and_development: 'Support et développement',
                round_the_clock_support: 'Support 24h/24:',
                regular_updates_and_improvements: 'Mises à jour régulières et améliorations:',
                performance_monitoring: 'Surveillance des performances:',
                data_security_and_protection: 'Sécurité et protection des données:',
                any_language: "Adoptez n'importe quelle langue",
                any_currency: "Acceptez n'importe quelle devise",
                no_limitations: 'Options de produit illimitées',
                unique_url: 'Site unique, URL unique',
            },
        },
        {
            code: 'de',
            string: {
                ...FORM_CREATE?.de,
                ...LANGS?.de,
                ...LONG_PHRASES?.de,
                hero_title: 'Erstellen und Bewerben von Online-Katalogen.',
                hero_description:
                    'bietet eine vollständige Palette von Dienstleistungen zur Erstellung und Bewerbung von Online-Katalogen, um Ihnen zu helfen, mehr Kunden anzuziehen und den Umsatz zu steigern',
                create_catalog: 'Katalog erstellen',
                enter_phone_or_email: 'Telefonnummer oder E-Mail',
                request: 'Anfrage',
                settings: 'Einstellungen',
                start: 'Start',
                advertising: 'Werbung',
                create_title: 'Online-Katalog erstellen — 4 einfache Schritte',
                contacts: 'Kontakte',
                message: 'Nachricht',
                feedback_form: 'Feedback-Formular',
                email: 'Email',
                send: 'Senden',
                phone_number: 'Telefonnummer',
                clear_form: 'Formular leeren',
                request_sended_successfully: 'Anfrage erfolgreich gesendet',
                ok: 'Ok',
                language: 'Sprache',
                view_sample: 'Beispiel ansehen',
                we_will_answer_soon: 'Wir werden uns so schnell wie möglich bei Ihnen melden',
                scope_of_application: 'Anwendungsbereich',
                social_media_commerce: 'Social Media Commerce',
                social_media_commerce_description:
                    'Die Integration Ihres Katalogs mit beliebten sozialen Netzwerken wie Instagram, Facebook, TikTok und Pinterest wird dazu beitragen, Ihr Publikum und Ihre Verkäufe zu steigern.',
                retail_and_small_business: 'Einzelhandel und Kleinunternehmen',
                retail_and_small_business_description:
                    'Ihr Online-Katalog bietet sowohl einzelnen Kunden als auch kleinen Unternehmen eine einfache und bequeme Möglichkeit zum Einkaufen.',
                wholesale_and_medium_business: 'Groß- und Mittelunternehmen',
                wholesale_and_medium_business_description:
                    'Ihr Online-Katalog erleichtert den Bestellprozess für Großkunden und bietet eine bequeme Möglichkeit, Produkte und Dienstleistungen für Firmenkunden zu präsentieren.',
                b2b: 'B2B',
                b2b_description:
                    'Ein Online-Katalog hilft Unternehmen im B2B-Segment, ihre Produkte und Dienstleistungen zu präsentieren und vereinfacht den Such- und Bestellvorgang für Hersteller und große Lieferanten.',
                social_media_promotion: 'Social Media Promotion',
                social_media_promotion_description:
                    'Die Promotion des Katalogs in beliebten sozialen Netzwerken, um neue Kunden zu gewinnen und den Umsatz zu steigern.',
                google_promotion: 'Google-Promotion',
                google_promotion_description:
                    'Werben und fördern Sie den Katalog in der Google-Suchmaschine, um die Online-Sichtbarkeit zu erhöhen und die Zielgruppe anzusprechen.',
                email_marketing_and_mailings: 'E-Mail-Marketing und Mailings',
                email_marketing_and_mailings_description:
                    'Nutzen Sie E-Mail-Marketing und Mailings, um den Katalog zu bewerben und die Aufmerksamkeit potenzieller Kunden zu erregen.',
                collaboration_with_bloggers: 'Zusammenarbeit mit Bloggern',
                collaboration_with_bloggers_description:
                    'Zusammenarbeit mit Bloggern oder Influencern, um Produkte aus dem Katalog über ihre sozialen Medien und Blogs zu bewerben.',
                support_and_development: 'Support und Entwicklung',
                round_the_clock_support: 'Rund-um-die-Uhr-Unterstützung:',
                regular_updates_and_improvements: 'Regelmäßige Updates und Verbesserungen:',
                performance_monitoring: 'Leistungsüberwachung:',
                data_security_and_protection: 'Datensicherheit und Datenschutz:',
                any_language: 'Jede Sprache verwenden',
                any_currency: 'Beliebige Währung akzeptieren',
                no_limitations: 'Unbegrenzte Produktoptionen',
                unique_url: 'Einzigartige Website, eindeutige URL',
            },
        },
    ];

    useEffect(() => {
        setCurrentLanguage(langList.find(({ code }) => code === lang) || { code: '', string: {} }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    return { currentLanguage };
};
