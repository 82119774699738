import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import StepConnector from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { useDevice } from 'hooks/useDevice';
import { Box, Typography } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { LandingContextInterface } from 'types/outlet_context_models';
import { useOutletContext } from 'react-router-dom';
import { Colors } from 'constants/colors';
import RequestButton from './RequestButton';

export default function CreateSteps() {
    const { string }: LandingContextInterface = useOutletContext(); //eslint-disable-line
    const { sx } = useDevice();

    const steps = [string?.request, string?.settings, string?.start, string?.advertising];

    const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean; icon: number };
    }>(({ ownerState }) => ({
        zIndex: 1,
        color: '#fff',
        width: sx ? 50 : 100,
        height: sx ? 50 : 100,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.icon === 1 && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(33, 165, 242) 0%, rgb(64, 137, 233) 50%, rgb(23, 13, 116) 100%)',
        }),
        ...(ownerState.icon === 2 && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(33, 165, 242) 0%, rgb(64, 137, 233) 50%, rgb(23, 13, 116) 100%)',
        }),
        ...(ownerState.icon === 3 && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(76, 175, 80) 0%, rgb(139, 195, 74) 50%, rgb(27, 94, 32) 100%)',
        }),
        ...(ownerState.icon === 4 && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(33, 165, 242) 0%, rgb(64, 137, 233) 50%, rgb(23, 13, 116) 100%)',
        }),
    }));

    function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className, icon } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <TouchAppIcon sx={{ fontSize: sx ? 24 : 40 }} />,
            2: <SettingsIcon sx={{ fontSize: sx ? 24 : 40 }} />,
            3: <FlagIcon sx={{ fontSize: sx ? 24 : 40 }} />,
            4: <TrendingUpIcon sx={{ fontSize: sx ? 24 : 40 }} />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active, icon: Number(icon) }} className={className}>
                {icons[String(icon)]}
            </ColorlibStepIconRoot>
        );
    }

    return (
        <Stack sx={{ width: '100%', mb: 4 }} spacing={4}>
            <Box px={2} sx={{ width: '100%', textAlign: 'center', background: Colors?.GRAY_300, py: 1 }}>
                <Typography sx={{ fontSize: sx ? 24 : 32, fontWeight: 700 }}>{string?.create_title}</Typography>
            </Box>
            <Stepper
                alternativeLabel
                connector={
                    <StepConnector
                        sx={{
                            top: sx ? 25 : 50,
                            height: 3,
                            background:
                                'linear-gradient( 136deg, rgb(16, 79, 116) 0%, rgb(64, 137, 233) 50%, rgb(63, 146, 194) 100%)',
                        }}
                    />
                }
            >
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box
                px={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    margin: '0 auto',
                }}
            >
                <RequestButton />
            </Box>
        </Stack>
    );
}
