import { Box, Typography, IconButton } from '@mui/material';
import { Colors } from 'constants/colors';
import { TelegramIcon } from 'assets/svg/telega.js';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CallIcon from '@mui/icons-material/Call';

interface HeaderInterface {
    footerHeight: number;
    appXPadding: number;
    string;
}

const Footer = ({ footerHeight, appXPadding }: HeaderInterface) => {
    return (
        <Box
            px={appXPadding}
            py={1}
            sx={{
                minHeight: `${footerHeight}px`,
                width: '100%',
                zIndex: 0,
                backgroundColor: Colors?.BLACK,
                overflow: 'hidden',
                display: 'flex',
            }}
        >
            {[
                {
                    firstName: 'Sales Nest Manager',
                    lastName: '',
                    contacts: {
                        phone: '+380668652127',
                        whatsapp: '+380665738771',
                        viber: '+380665738771',
                        telegram: 'SNBusinessApp',
                        emailAddress: 'salesnest.info@gmail.com',
                    },
                },
            ].map((manager, idx) => {
                return (
                    <Box key={idx} sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        {manager.contacts?.phone && (
                            <a
                                href={`tel:${manager.contacts?.phone}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8,
                                }}
                            >
                                <IconButton
                                    sx={{
                                        backgroundColor: '#fff',
                                        border: '2px solid #646464',
                                        width: 25,
                                        height: 25,
                                        color: '#000',
                                    }}
                                >
                                    <CallIcon color="inherit" sx={{ fontSize: 18 }} />
                                </IconButton>
                                <Typography variant={'h4'} sx={{ color: '#fff' }}>
                                    {manager.contacts?.phone}
                                </Typography>
                            </a>
                        )}
                        {manager.contacts?.telegram && (
                            <a
                                href={`https://t.me/${manager.contacts?.telegram}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8,
                                }}
                            >
                                <Box sx={{ width: 25, height: 25 }}>{TelegramIcon()}</Box>
                                <Typography variant={'h4'} sx={{ color: '#fff' }}>
                                    @our_telegram
                                </Typography>
                            </a>
                        )}
                        {manager.contacts?.emailAddress && (
                            <a
                                href={`mailto:${manager.contacts?.emailAddress}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8,
                                }}
                            >
                                <IconButton
                                    sx={{
                                        backgroundColor: '#fff',
                                        border: '2px solid #646464',
                                        width: 25,
                                        height: 25,
                                        color: '#000',
                                    }}
                                >
                                    <AlternateEmailIcon color="inherit" sx={{ fontSize: 16 }} />
                                </IconButton>
                                <Typography variant={'h4'} sx={{ color: '#fff' }}>
                                    {manager.contacts?.emailAddress}
                                </Typography>
                            </a>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default Footer;
